import React, { useState } from "react";
import { MdOutlineModeEdit } from "react-icons/md";

import Modal from "../../../../../../components/modal";
import QueryBuilder from "../../../../../../components/advanced-query-builder";
import { hasCompleteRule } from "../../../../../../components/advanced-query-builder/helpers.js";
import PreviewQuery from "../../../../../../components/advanced-query-preview";
import Button from "../../../../../../components/button";
import { Heading3 } from "../../../../../../components/typography/index.js";

import { useIdentifyCohortConfigs } from "../../../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../../../hooks/useIdentifySubcohortList";

import {
  Buttons,
  Footer,
  ContentWrapper,
  EditButton,
  HeaderWrapper,
} from "./styles.js";

const AQBModal = ({
  isShowingModal,
  toggleModal,
  subcohort,
  subcohortID,
  isEditing,
  removeSubcohortFromDashboard,
}) => {
  const { dispatch } = useIdentifySubcohortList();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { filtersConfig } = cohortConfigs;
  const [advancedQuery, setAdvancedQuery] = useState({});

  const fields = Object.keys(filtersConfig).map((field) => ({
    value: filtersConfig[field].key,
    label: filtersConfig[field].label,
    type: filtersConfig[field].type,
    options: filtersConfig[field].options,
  }));

  function handleApplyQuery(query) {
    dispatch({
      type: "APPLY_SUBCOHORT",
      payload: {
        id: subcohortID,
        name: subcohortID
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase()),
        query: query,
        isSaved: false,
        isAdvanced: true,
      },
    });
    toggleModal();
  }

  function handleEditQuery() {
    dispatch({
      type: "UPDATE_SUBCOHORT_STATUS",
      payload: {
        id: subcohortID,
        status: "isEditing",
      },
    });
  }

  function getButtons() {
    const footer = (
      <>
        <Footer>
          {isEditing ? (
            <Button
              variant="outlineDanger"
              onClick={(e) => {
                e.stopPropagation();
                let confirmation = removeSubcohortFromDashboard(true);
                if (confirmation) toggleModal();
              }}
            >
              Delete Subcohort
            </Button>
          ) : (
            <span />
          )}

          <Buttons>
            <Button variant="clear" onClick={toggleModal}>
              Cancel
            </Button>
            <Button
              type="submit"
              form="queryBuilder"
              variant="confirm"
              disabled={!hasCompleteRule(advancedQuery)}
            >
              {isEditing ? "Update" : "Apply"}
            </Button>
          </Buttons>
        </Footer>
      </>
    );
    if (subcohort.name === "" || isEditing) return footer;
    return null;
  }

  return (
    <Modal
      title="Advanced Filters"
      isShowing={isShowingModal}
      hide={toggleModal}
      hideOnClickOutside={false}
      footer={getButtons()}
    >
      <ContentWrapper>
        {subcohort.name !== "" && !isEditing ? (
          <HeaderWrapper>
            <Heading3>{subcohort.name}</Heading3>
            <EditButton onClick={handleEditQuery}>
              <MdOutlineModeEdit />
              Edit Subcohort
            </EditButton>
          </HeaderWrapper>
        ) : (
          ""
        )}
        {subcohort.name === "" || isEditing ? (
          <QueryBuilder
            id={"queryBuilder"}
            fields={fields}
            name={subcohort.name}
            initialQuery={isEditing ? subcohort.query : null}
            onSubmitQuery={handleApplyQuery}
            onQueryChange={(query) => setAdvancedQuery(query)}
            maxGroupDepth={3}
          />
        ) : (
          <PreviewQuery fields={fields} query={subcohort.query} />
        )}
      </ContentWrapper>
    </Modal>
  );
};

export default AQBModal;
