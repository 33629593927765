import { isEmpty, isNumber } from "lodash";
import React, { useState } from "react";
import { MdLocationPin } from "react-icons/md";
import PieChart from "../../../../../../components/pie-chart-draft";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import Loader from "../../../../../../components/loader";
import {
  Heading2,
  Heading3,
  Heading4,
  Small,
} from "../../../../../../components/typography";

import {
  Container,
  Header,
  Content,
  EligiblePatients,
  Zip3,
  EligiblePatientsCount,
  Breakdown,
  BreakdownHeader,
  BreakdownContent,
  ErrorMessageWrapper,
  Collapsible,
  CollapsibleHeader,
  CollapsibleList,
} from "./styles";

export default function SiteDetails({ site }) {
  if (!isEmpty(site)) {
    let name = site.institution ? (
      <>
        <MdLocationPin />
        {site.institution}
      </>
    ) : (
      site.pi_name
    );

    let PIName = site["pi_first"] ? (
      <div>
        <Small color="#666666">PI: </Small>
        <span>
          {site["pi_first"]
            ? `${site["pi_first"]} ${site["pi_last"]}`
            : "Unknown"}
          {site["email"] ? ` - ${site["email"]}` : ""}
        </span>
      </div>
    ) : (
      ""
    );

    const eligiblePatients = site["eligible_patients"] ? (
      <EligiblePatients>
        <Zip3>Patients by Site</Zip3>
        <EligiblePatientsCount>
          Eligible patients
          <Heading3 color="#666666" marginBottom={0}>
            {site["eligible_patients"]
              ? site["eligible_patients"].toLocaleString("en-US")
              : "N/A"}
          </Heading3>
        </EligiblePatientsCount>
      </EligiblePatients>
    ) : (
      ""
    );
    const [locationOpen, setLocationOpen] = useState(false);

    const associatedLocations = site.associated_locations ? (
      <Collapsible>
        <CollapsibleHeader onClick={() => setLocationOpen(!locationOpen)}>
          Other affiliated locations
          {locationOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </CollapsibleHeader>
        {locationOpen && (
          <CollapsibleList>
            {site.associated_locations.map((location) => (
              <span>{location}</span>
            ))}
          </CollapsibleList>
        )}
      </Collapsible>
    ) : (
      ""
    );

    return (
      <Container>
        <Header>
          <div>
            <Heading2>{name}</Heading2>
            <Small color="var(--tertiary)" marginBottom={10}>
              {site.address}, {site.city}, {site.state} {site.zipcode}
            </Small>
          </div>
          {PIName}
          {associatedLocations}
        </Header>
        {eligiblePatients}

        {site["eligible_patients"] || site["patient_count"] ? (
          <>
            {isNumber(site["eosinophil__gte_0_lt_250"]) ||
            isNumber(site["eosinophil__gte_250_lt_300"]) ||
            isNumber(site["eosinophil__gte_300_lt_1500"]) ||
            isNumber(site["eosinophil__gte_1500"]) ||
            isNumber(site["eosinophil__null"]) ? (
              <Breakdown>
                <BreakdownHeader>
                  <Heading4 color="--secundary" marginBottom={0}>
                    Patients with COPD (N)
                  </Heading4>
                </BreakdownHeader>
                <BreakdownContent columns={2}>
                  <div>
                    <Heading4 color="--secundary">EOS 0-250</Heading4>
                    <Heading4>
                      {site["eosinophil__gte_0_lt_250"].toLocaleString("en-US")}
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">EOS 250-300</Heading4>
                    <Heading4>
                      {site["eosinophil__gte_250_lt_300"].toLocaleString(
                        "en-US"
                      )}
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">EOS 300-1500</Heading4>
                    <Heading4>
                      {site["eosinophil__gte_300_lt_1500"].toLocaleString(
                        "en-US"
                      )}
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">EOS +1500</Heading4>
                    <Heading4>
                      {site["eosinophil__gte_1500"].toLocaleString("en-US")}
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">No EOS</Heading4>
                    <Heading4>
                      {site["eosinophil__null"].toLocaleString("en-US")}
                    </Heading4>
                  </div>
                </BreakdownContent>
              </Breakdown>
            ) : (
              ""
            )}

            {isNumber(site["eosinophil_estimate__gte_0_lt_250"]) ||
            isNumber(site["eosinophil_estimate__gte_250_lt_300"]) ||
            isNumber(site["eosinophil_estimate__gte_300_lt_1500"]) ||
            isNumber(site["eosinophil_estimate__gte_1500"]) ? (
              <Breakdown>
                <BreakdownHeader>
                  <Heading4 color="--secundary" marginBottom={0}>
                    Patients with COPD (N-Estimated)
                  </Heading4>
                </BreakdownHeader>
                <BreakdownContent columns={2}>
                  <div>
                    <Heading4 color="--secundary">EOS 0-250</Heading4>
                    <Heading4>
                      {site["eosinophil_estimate__gte_0_lt_250"].toLocaleString(
                        "en-US"
                      )}
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">EOS 250-300</Heading4>
                    <Heading4>
                      {site[
                        "eosinophil_estimate__gte_250_lt_300"
                      ].toLocaleString("en-US")}
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">EOS 300-1500</Heading4>
                    <Heading4>
                      {site[
                        "eosinophil_estimate__gte_300_lt_1500"
                      ].toLocaleString("en-US")}
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">EOS +1500</Heading4>
                    <Heading4>
                      {site["eosinophil_estimate__gte_1500"].toLocaleString(
                        "en-US"
                      )}
                    </Heading4>
                  </div>
                </BreakdownContent>
              </Breakdown>
            ) : (
              ""
            )}

            {isNumber(site["pts_with_0_risk_factors"]) &&
            isNumber(site["pts_with_1_risk_factors"]) &&
            isNumber(site["pts_with_2_risk_factors"]) &&
            isNumber(site["pts_with_3_risk_factors"]) ? (
              <Breakdown>
                <BreakdownHeader>
                  <Heading4 color="--secundary" marginBottom={0}>
                    Patients with Risk factors
                  </Heading4>
                </BreakdownHeader>
                <BreakdownContent columns={2}>
                  <div>
                    <Heading4 color="--secundary">0 risk factors</Heading4>
                    <Heading4>
                      {site["pts_with_0_risk_factors"]?.toFixed(2)}%
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">1 risk factor</Heading4>
                    <Heading4>
                      {site["pts_with_1_risk_factors"]?.toFixed(2)}%
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">2 risk factors</Heading4>
                    <Heading4>
                      {site["pts_with_2_risk_factors"]?.toFixed(2)}%
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">3 risk factors</Heading4>
                    <Heading4>
                      {site["pts_with_3_risk_factors"]?.toFixed(2)}%
                    </Heading4>
                  </div>
                </BreakdownContent>
              </Breakdown>
            ) : (
              ""
            )}

            {site["ascvd_under_7point5_percent"] ||
            site["ascvd_7point5_to_20_percent"] ||
            site["ascvd_over_20_percent"] ? (
              <Breakdown>
                <BreakdownHeader>
                  <Heading4 color="--secundary" marginBottom={0}>
                    10-year ASCVD Risk
                  </Heading4>
                </BreakdownHeader>
                <BreakdownContent columns={2}>
                  {site["ascvd_under_7point5_percent"] ? (
                    <div>
                      <Heading4 color="--secundary">&#8804;7.5%</Heading4>
                      <Heading4>
                        {site["ascvd_under_7point5_percent"]?.toFixed(2)}%
                      </Heading4>
                    </div>
                  ) : (
                    ""
                  )}
                  {site["ascvd_7point5_to_20_percent"] ? (
                    <div>
                      <Heading4 color="--secundary">7.5% - 20%</Heading4>
                      <Heading4>
                        {site["ascvd_7point5_to_20_percent"]?.toFixed(2)}%
                      </Heading4>
                    </div>
                  ) : (
                    ""
                  )}
                  {site["ascvd_over_20_percent"] ? (
                    <div>
                      <Heading4 color="--secundary">&gt;20%: </Heading4>
                      <Heading4>
                        {site["ascvd_over_20_percent"]?.toFixed(2)}%
                      </Heading4>
                    </div>
                  ) : (
                    ""
                  )}
                </BreakdownContent>
              </Breakdown>
            ) : (
              ""
            )}

            {site["ldl_70_to_100_mg_per_dl"] &&
            site["ldl_100_to_190_mg_per_dl"] ? (
              <Breakdown>
                <BreakdownHeader>
                  <Heading4 color="--secundary" marginBottom={0}>
                    LDL Cholesterol
                  </Heading4>
                </BreakdownHeader>
                <BreakdownContent columns={2}>
                  <div>
                    <Heading4 color="--secundary">70 - 100 mg/dL</Heading4>
                    <Heading4>
                      {site["ldl_70_to_100_mg_per_dl"]?.toFixed(2)}%
                    </Heading4>
                  </div>
                  <div>
                    <Heading4 color="--secundary">100 - 190 mg/dL</Heading4>
                    <Heading4>
                      {site["ldl_100_to_190_mg_per_dl"]?.toFixed(2)}%
                    </Heading4>
                  </div>
                </BreakdownContent>
              </Breakdown>
            ) : (
              ""
            )}

            {site["zip3_eligible_high_event_risk"] ||
            site["zip3_eligible_low_event_risk"] ||
            site["zip3_eligible_med_event_risk"] ? (
              <Breakdown>
                <BreakdownHeader>
                  <Heading4 color="--secundary" marginBottom={0}>
                    MACE Risk Breakdown
                  </Heading4>
                </BreakdownHeader>
                <BreakdownContent columns={1}>
                  <PieChart
                    data={[
                      {
                        key: "Low",
                        value: site["zip3_eligible_low_event_risk"].toFixed(2),
                      },
                      {
                        key: "Moderate",
                        value: site["zip3_eligible_med_event_risk"].toFixed(2),
                      },
                      {
                        key: "High",
                        value: site["zip3_eligible_high_event_risk"].toFixed(2),
                      },
                    ]}
                    outerRadius={60}
                    innerRadius={30}
                    colorPalette={["#6DCE66", "#F1D386", "#CA3838"]}
                  />
                </BreakdownContent>
              </Breakdown>
            ) : (
              ""
            )}

            {site["hp_network_status"] ||
            site["icmpgn_literature"] ||
            site["fwa"] ? (
              <Breakdown>
                <BreakdownContent columns={2}>
                  {site["hp_network_status"] && (
                    <div>
                      <Heading4 color="--secundary">
                        HealthPals In-Network Status
                      </Heading4>
                      <Heading4>{site["hp_network_status"]}</Heading4>
                    </div>
                  )}
                  {site["icmpgn_literature"] && (
                    <div>
                      <Heading4 color="--secundary">
                        IC-MPGN Engagement in Literature
                      </Heading4>
                      <Heading4>{site["icmpgn_literature"]}</Heading4>
                    </div>
                  )}
                  {site["fwa"] && (
                    <div>
                      <Heading4 color="--secundary">FWA</Heading4>
                      <Heading4>{site["fwa"]}</Heading4>
                    </div>
                  )}
                </BreakdownContent>
              </Breakdown>
            ) : (
              ""
            )}
          </>
        ) : (
          // <ErrorMessageWrapper>
          //   <p>
          //     This resulted in <b>too few patients</b>.
          //   </p>
          //   <p>
          //     To protect patient privacy, we are unable to display the results.
          //   </p>
          //   <br />
          //   <p>Please try a different filter / combination of filters.</p>
          // </ErrorMessageWrapper>
          ""
        )}
      </Container>
    );
  }

  return (
    <Container>
      <Loader />
    </Container>
  );
}
