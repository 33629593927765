import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  background: #ffffff;

  border: 1px solid;
  border-radius: 3px;
  border-color: #dee4e7;

  padding: 5px 12px 5px 8px;
  height: 30px;

  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      border-color: #edf0f2;
      background-color: #f5f7f8;
      opacity: 1;
      color: #cccccc;
    `}

  ${(props) =>
    props.readOnly &&
    css`
      cursor: not-allowed;
      border-color: #c1c8d4;
      background: #f2f2f2;
    `}

  ${(props) =>
    props.invalid &&
    css`
      border-color: #dd2542;
    `}


  &:focus-within {
    outline: none;
    border-color: #3fafd1;
    color: #50565f;
  }

  input {
    background-color: #ffffff;
    cursor: text;
    width: 100%;
    padding: 0px;
    border-width: 0px;
    flex: 1 1 0%;
    color: #26344c;

    &:focus {
      outline: none;
    }

    &[readonly] {
      opacity: 1;
      color: #666666;
      background: #f2f2f2;
    }

    &[disabled] {
      opacity: 1;
      color: #cccccc;
      background-color: #f5f7f8;
      &::placeholder {
        color: #cccccc;
        opacity: 1;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  svg + input {
    margin-left: 10px;
  }

  input + svg {
    margin-left: 10px;
    cursor: pointer;
  }
`;
