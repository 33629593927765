import { isEmpty } from "lodash";
import React, { useRef, useState, useEffect } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { RiEdit2Line } from "react-icons/ri";

import {
  validateQuery,
  addRuleToGroup,
  addGroupToGroup,
  removeRuleFromGroup,
  removeGroupFromGroup,
  updateGroup,
  cleanQuery,
  hasCompleteRule,
} from "./helpers";
import RuleGroup from "./rule-group";
import QueryViewer from "../advanced-query-preview";

import {
  QueryBuilderContainer,
  QueryBuilderHeader,
  PreviewButton,
} from "./styles";
import { Heading3 } from "../typography";

const QueryBuilder = ({
  name,
  id,
  fields,
  onQueryChange,
  initialQuery,
  onSubmitQuery,
  maxGroupDepth = 100,
}) => {
  const [query, setQuery] = useState(
    initialQuery || {
      type: "group",
      combinator: "and",
      rules: [],
    }
  );
  const [errors, setErrors] = useState({});
  const [viewQuery, setViewQuery] = useState(false);

  const lastAddedRef = useRef(null);

  useEffect(() => {
    if (lastAddedRef.current) {
      lastAddedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (onQueryChange) onQueryChange(query);
  }, [query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateQuery(query, fields);
    if (onSubmitQuery && isEmpty(newErrors)) onSubmitQuery(cleanQuery(query));
    setErrors(newErrors);
  };

  const handleAddRule = (groupIndex) => {
    let id = [...groupIndex];
    setQuery((prevQuery) => {
      let newquery = updateGroup(prevQuery, groupIndex, addRuleToGroup, id);
      if (onQueryChange) onQueryChange(newquery);
      return newquery;
    });
  };

  const handleAddGroup = (groupIndex) => {
    let id = [...groupIndex];
    setQuery((prevQuery) => {
      let newquery = updateGroup(prevQuery, groupIndex, addGroupToGroup, id);
      if (onQueryChange) onQueryChange(newquery);
      return newquery;
    });
  };

  const handleRemoveRule = (groupIndex, ruleIndex) => {
    setQuery((prevQuery) => {
      let newquery = updateGroup(
        prevQuery,
        groupIndex,
        removeRuleFromGroup,
        ruleIndex
      );
      if (onQueryChange) onQueryChange(newquery);
      return newquery;
    });
  };

  const handleRemoveGroup = (groupIndex) => {
    setQuery((prevQuery) => {
      let newquery = removeGroupFromGroup(prevQuery, groupIndex);
      if (onQueryChange) onQueryChange(newquery);
      return newquery;
    });
  };

  const handleRuleChange = (groupIndex, newRules) => {
    setQuery((prevQuery) => {
      let newquery = updateGroup(prevQuery, groupIndex, (group) => ({
        ...group,
        rules: newRules,
      }));
      if (onQueryChange) onQueryChange(newquery);
      return newquery;
    });
  };

  const handleCombinatorChange = (groupIndex, newCombinator) => {
    setQuery((prevQuery) => {
      let newquery = updateGroup(prevQuery, groupIndex, (group) => ({
        ...group,
        combinator: newCombinator,
      }));
      if (onQueryChange) onQueryChange(newquery);
      return newquery;
    });
  };

  const renderRuleGroup = (group, groupIndex = []) => (
    <RuleGroup
      key={groupIndex.join("-")}
      id={groupIndex.join("-")}
      rules={group.rules}
      combinator={group.combinator}
      onAddRule={handleAddRule}
      onAddGroup={handleAddGroup}
      onRemoveRule={handleRemoveRule}
      onRemoveGroup={handleRemoveGroup}
      onRuleChange={handleRuleChange}
      onCombinatorChange={handleCombinatorChange}
      fields={fields}
      lastAddedRef={lastAddedRef}
      errors={errors}
      maxGroupDepth={maxGroupDepth}
    />
  );

  return (
    <QueryBuilderContainer id={id} onSubmit={handleSubmit} action="" method="">
      <QueryBuilderHeader>
        <Heading3>{name}</Heading3>
        {hasCompleteRule(query) && (
          <PreviewButton
            type="button"
            onClick={() => setViewQuery(!viewQuery)}
            active={viewQuery}
          >
            {viewQuery ? (
              <>
                <RiEdit2Line />
                Edit
              </>
            ) : (
              <>
                <IoEyeOutline />
                Preview
              </>
            )}
          </PreviewButton>
        )}
      </QueryBuilderHeader>
      {viewQuery ? (
        <QueryViewer fields={fields} query={query} />
      ) : (
        renderRuleGroup(query)
      )}
    </QueryBuilderContainer>
  );
};

export default QueryBuilder;
