import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const Header = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding: 10px;
`;

export const EligiblePatients = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

export const Zip3 = styled.div`
  font-weight: 500;
  color: #26344d;
`;
export const EligiblePatientsCount = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666666;
`;

export const Breakdown = styled.div``;

export const BreakdownHeader = styled.div`
  text-align: center;
  background-color: #ededed;
  padding: 5px;
  width: 100%;
`;

export const BreakdownContent = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  & > div {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const ErrorMessageWrapper = styled.div`
  color: #666;
`;

export const Collapsible = styled.div`
  padding: 5px;
`;
export const CollapsibleHeader = styled.div`
  color: #4d5f80;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const CollapsibleList = styled.div`
  > span {
    color: #4d5f80;
    font-weight: 700;
    font-size: 12px;
    display: block;
    padding: 5px;
  }
`;
