import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import {
  Container,
  SearchInput,
  OptionList,
  OptionItem,
  CategoryLabel,
  DropdownIcon,
} from "./styles";

const SearchableSelect = ({
  options = [],
  onChange,
  value,
  disabled = false,
  placeholder,
  borderless = false,
  marginBottom = 10,
  marginTop = 0,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedValue, setSelectedValue] = useState("");
  const dropdownRef = useRef(null); // Refs for option list
  const inputRef = useRef(null); // Refs for input
  const optionRefs = useRef([]); // Refs for each option
  const clickFlag = useRef(false); // Ref to track if click triggered the focus
  const [dropdownPosition, setDropdownPosition] = useState({});
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Track the highlighted option index

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (isOpen) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setHighlightedIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          return nextIndex >= filteredOptions.length ? prevIndex : nextIndex;
        });
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setHighlightedIndex((prevIndex) => {
          const nextIndex = prevIndex - 1;
          return nextIndex < 0 ? prevIndex : nextIndex;
        });
      } else if (e.key === "Enter" && highlightedIndex >= 0) {
        e.preventDefault();
        const currentItem = getFlatOptions()[highlightedIndex];
        if (currentItem) {
          handleOptionClick(currentItem.value);
        }
      } else if (e.key === "Escape" || e.key === "Tab") {
        setIsOpen(false);
      }
    }
  };

  // Utility function to flatten the options array for easy indexing
  const getFlatOptions = () => {
    return filteredOptions.flatMap((groupOrOption) =>
      groupOrOption.options ? groupOrOption.options : [groupOrOption]
    );
  };

  // Effect to handle click outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Automatically scroll to the highlighted option
  useEffect(() => {
    if (highlightedIndex >= 0 && optionRefs.current[highlightedIndex]) {
      optionRefs.current[highlightedIndex].scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [highlightedIndex]);

  // Effect to calculate and set the position of the dropdown relative to the input field
  useEffect(() => {
    if (isOpen && inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setFilteredOptions(
      options
        .map((groupOrOption) => {
          if ("options" in groupOrOption) {
            return {
              ...groupOrOption,
              options: groupOrOption.options.filter((option) =>
                option.label.toLowerCase().includes(searchTerm.toLowerCase())
              ),
            };
          }
          return groupOrOption.label
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
            ? groupOrOption
            : null;
        })
        .filter((item) => item !== null)
    );
  }, [searchTerm, options]);

  useEffect(() => {
    if (value !== "") {
      const selectedOption = options
        .flatMap((groupOrOption) =>
          groupOrOption.options ? groupOrOption.options : groupOrOption
        )
        .find((opt) => opt.value === value);

      setSelectedValue(selectedOption);
    } else {
      setSelectedValue("");
    }
  }, [value]);

  const handleOptionClick = (inputValue) => {
    const selectedOption = options
      .flatMap((groupOrOption) =>
        groupOrOption.options ? groupOrOption.options : groupOrOption
      )
      .find((opt) => opt.value === inputValue);
    setSelectedValue(selectedOption);
    setIsOpen(false);
    setSearchTerm(""); // reset search term after selection
    if (onChange) onChange(inputValue);
  };

  // Set the flag when a click is detected on the input
  const handleMouseDown = () => {
    clickFlag.current = true;
  };

  // Reset the flag after the mouse click
  const handleMouseUp = () => {
    clickFlag.current = false;
  };

  const toggleDropdown = (e) => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (!isOpen) setIsOpen(true);
  };

  // Automatically open the dropdown when the input is focused
  const handleFocus = () => {
    if (!isOpen && !clickFlag.current) setIsOpen(true);
  };

  // Automatically close the dropdown when the input is focused
  const handleBlur = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      isOpen
    ) {
      setIsOpen(false);
    }
  };

  // Render dropdown using React Portal
  const dropdown = isOpen ? (
    <OptionList
      ref={dropdownRef}
      style={{
        position: "absolute",
        top: `${dropdownPosition.top}px`,
        left: `${dropdownPosition.left}px`,
        width: `${dropdownPosition.width}px`,
      }}
    >
      {filteredOptions.length > 0 ? (
        filteredOptions.map((groupOrOption, index) => {
          if (groupOrOption.options) {
            return (
              <React.Fragment key={groupOrOption.label}>
                <CategoryLabel>{groupOrOption.label}</CategoryLabel>
                {groupOrOption.options.map((option, i) => (
                  <OptionItem
                    ref={(el) => (optionRefs.current[`${index}-${i}`] = el)} // Add ref to track each option
                    key={option.value}
                    onClick={() => handleOptionClick(option.value)}
                    highlighted={index === highlightedIndex}
                  >
                    {option.label}
                  </OptionItem>
                ))}
              </React.Fragment>
            );
          } else {
            return (
              <OptionItem
                ref={(el) => (optionRefs.current[index] = el)} // Add ref to track each option
                key={`${groupOrOption.value} ${index}`}
                onClick={() => handleOptionClick(groupOrOption.value)}
                highlighted={index === highlightedIndex}
              >
                {groupOrOption.label}
              </OptionItem>
            );
          }
        })
      ) : (
        <OptionItem disabled>No options found</OptionItem>
      )}
    </OptionList>
  ) : null;

  const getPlaceholder = () => {
    if (isOpen) return selectedValue.label || "Search";

    return selectedValue.label || placeholder || "Select...";
  };

  return (
    <Container
      ref={inputRef}
      disabled={disabled}
      marginBottom={marginBottom}
      marginTop={marginTop}
      borderless={borderless}
      {...rest}
    >
      <SearchInput
        type="text"
        placeholder={getPlaceholder()}
        value={
          isOpen ? searchTerm : selectedValue === "" ? "" : selectedValue.label
        }
        onClick={toggleDropdown}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        readOnly={!isOpen} // Only allow typing when dropdown is open
        onFocus={handleFocus} // Opens the dropdown on input focus
        onBlur={handleBlur} // Close the dropdown on input blur
        onMouseDown={handleMouseDown} // Set the click flag
        onMouseUp={handleMouseUp} // Reset the click flag
      />
      <DropdownIcon>
        <MdKeyboardArrowDown size={20} />
      </DropdownIcon>
      {createPortal(dropdown, document.body)}
    </Container>
  );
};

export default SearchableSelect;
