import React, { useMemo, useState } from "react";
import { IoMdAdd, IoIosRemoveCircleOutline } from "react-icons/io";
import { IoChevronUp, IoChevronDown } from "react-icons/io5";
import { MdClose } from "react-icons/md";

import Rule from "../rule";
import {
  RuleGroupContainer,
  RulesWrapper,
  RuleGroupHeader,
  RuleGroupFooter,
  RuleGroupContent,
  EmptyStateContent,
  RuleContainer,
  CombinatorButton,
  Button,
  Buttons,
  CollapseButton,
} from "./styles";
import { Small } from "../../typography";

const RuleGroup = ({
  id,
  rules,
  combinator,
  onAddRule,
  onAddGroup,
  onRemoveRule,
  onRemoveGroup,
  onRuleChange,
  onCombinatorChange,
  fields,
  lastAddedRef,
  errors,
  maxGroupDepth,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleRuleChange = (index, newRule) => {
    const newRules = [...rules];
    newRules[index] = newRule;
    onRuleChange(groupIndexArray, newRules);
  };

  const handleCombinatorToggle = () => {
    const newCombinator = combinator === "and" ? "or" : "and";
    onCombinatorChange(groupIndexArray, newCombinator);
  };

  const groupIndexArray = useMemo(() => {
    return [...id.split("-").slice(1).map(Number)];
  }, [id]);

  const groupName = useMemo(() => {
    switch (groupIndexArray.length) {
      case 0:
        return "";
      case 1:
        return `Criteria`;
      case 2:
        return `Sub-criteria`;
      default:
        return `Group`;
    }
  }, [groupIndexArray]);

  const groupButtonName = useMemo(() => {
    switch (groupIndexArray.length) {
      case 0:
        return "Criteria";
      case 1:
        return `Sub-criteria`;
      default:
        return `Group`;
    }
  }, [groupIndexArray]);

  const getAddActionsButtons = (size) => {
    return (
      <>
        {groupIndexArray.length > 0 && (
          <Button type="button" onClick={() => onAddRule(groupIndexArray)}>
            <IoMdAdd />
            Rule
          </Button>
        )}
        {groupIndexArray.length < maxGroupDepth && (
          <Button
            type="button"
            groupDepth={groupIndexArray.length}
            onClick={() => onAddGroup(groupIndexArray)}
          >
            <IoMdAdd />
            {groupButtonName}
          </Button>
        )}
      </>
    );
  };

  return (
    <RuleGroupContainer groupDepth={groupIndexArray.length}>
      {groupIndexArray.length == 0 && rules.length == 0 ? (
        ""
      ) : (
        <RuleGroupHeader
          groupDepth={groupIndexArray.length}
          borderless={!isOpen}
        >
          {groupName}
          {groupIndexArray.length > 0 && (
            <Buttons groupDepth={groupIndexArray.length}>
              <Button
                type="button"
                onClick={() => {
                  let removeGroup = true;
                  if (rules.length > 0) {
                    removeGroup = window.confirm(
                      `Are you sure you want to remove ${groupName.toLowerCase()} ?`
                    );
                  }
                  if (removeGroup) {
                    onRemoveGroup(groupIndexArray);
                  }
                }}
              >
                <IoIosRemoveCircleOutline
                  color={groupIndexArray.length === 1 ? "#fff" : "#B4436C"}
                />
              </Button>
              <CollapseButton
                groupDepth={groupIndexArray.length}
                type="button"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? <IoChevronUp /> : <IoChevronDown />}
              </CollapseButton>
            </Buttons>
          )}
        </RuleGroupHeader>
      )}
      {isOpen &&
        (rules.length > 0 ? (
          <>
            <RuleGroupContent>
              {rules.map((rule, index) => (
                <RulesWrapper ref={lastAddedRef}>
                  {rule.rules ? (
                    <>
                      {index > 0 ? (
                        <CombinatorButton
                          type="button"
                          combinatorType={
                            groupIndexArray.length == 0 ? "criteria" : "group"
                          }
                          onClick={handleCombinatorToggle}
                        >
                          {combinator.toUpperCase()}
                        </CombinatorButton>
                      ) : (
                        ""
                      )}
                      <RuleGroup
                        key={`${id}-${index}`}
                        id={`${id}-${index}`}
                        rules={rule.rules}
                        combinator={rule.combinator}
                        onAddRule={onAddRule}
                        onAddGroup={onAddGroup}
                        onRemoveRule={onRemoveRule}
                        onRemoveGroup={onRemoveGroup}
                        onRuleChange={onRuleChange}
                        onCombinatorChange={onCombinatorChange}
                        fields={fields}
                        lastAddedRef={lastAddedRef}
                        errors={errors ? errors[rule.id] : {}}
                        maxGroupDepth={maxGroupDepth}
                      />
                    </>
                  ) : (
                    <>
                      {index > 0 ? (
                        <CombinatorButton
                          type="button"
                          combinatorType="rule"
                          onClick={handleCombinatorToggle}
                        >
                          {combinator.toUpperCase()}
                        </CombinatorButton>
                      ) : (
                        ""
                      )}
                      <RuleContainer key={`${id}-${index}`}>
                        <Rule
                          index={index}
                          rule={rule}
                          fields={fields}
                          onChange={handleRuleChange}
                          errors={errors ? errors[rule.id] : {}}
                        />
                        <Button
                          type="button"
                          onClick={() => onRemoveRule(groupIndexArray, index)}
                          style={{ padding: "5px 0" }}
                        >
                          <MdClose size={20} color="#50565F" />
                        </Button>
                      </RuleContainer>
                    </>
                  )}
                </RulesWrapper>
              ))}
            </RuleGroupContent>
            <RuleGroupFooter groupDepth={groupIndexArray.length}>
              {groupName !== "" ? (
                <Small marginBottom={0} color="#727B8B">
                  {groupName}
                </Small>
              ) : (
                ""
              )}

              {getAddActionsButtons()}
            </RuleGroupFooter>
          </>
        ) : (
          <EmptyStateContent groupDepth={groupIndexArray.length}>
            {getAddActionsButtons()}
          </EmptyStateContent>
        ))}
    </RuleGroupContainer>
  );
};

export default RuleGroup;
