import React from "react";
import { ValueContainer } from "./styles";
import SelectInput from "../../basic-inputs/select";
import Input from "../../basic-inputs/input";
import { XSmall } from "../../typography";
import { isUndefined } from "lodash";
const Value = ({
  selectedField,
  selectedOperator,
  value,
  fields,
  onChange,
  error,
}) => {
  const renderValueInput = () => {
    let options;
    if (!selectedField || !selectedOperator)
      return (
        <Input
          type="text"
          placeholder={"Choose value"}
          disabled={true}
          marginBottom={0}
          invalid={!isUndefined(error)}
        />
      );
    const fieldType = fields.find((f) => f.value === selectedField).type;
    switch (fieldType) {
      case "categorical":
        options = fields
          .find((f) => f.value === selectedField)
          .options.map((val) => ({ value: val, label: val }));
        options.unshift({ value: "", label: "Choose value" });
        return (
          <SelectInput
            value={value}
            onChange={onChange}
            options={options}
            marginBottom={0}
            invalid={!isUndefined(error)}
          ></SelectInput>
        );
      case "numerical":
        let [min, max] = fields.find((f) => f.value === selectedField).options;
        let placeholder =
          !isNaN(min) && !isNaN(max) ? `${min} - ${max}` : "Choose value";
        return (
          <Input
            type="number"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            marginBottom={0}
            invalid={!isUndefined(error)}
          />
        );
      case "boolean":
        options = [
          { value: "", label: "Choose value" },
          { value: "true", label: "True" },
          { value: "false", label: "False" },
        ];
        return (
          <SelectInput
            value={value}
            onChange={onChange}
            options={options}
            marginBottom={0}
            invalid={!isUndefined(error)}
          ></SelectInput>
        );
      default:
        return null;
    }
  };

  return (
    <ValueContainer>
      {renderValueInput()}
      {error ? (
        <XSmall color="#B4436C" marginTop={1}>
          {error}
        </XSmall>
      ) : (
        ""
      )}
    </ValueContainer>
  );
};

export default Value;
