import styled from "styled-components";

export const Container = styled.div`
  display: none;
  @media only screen and (min-height: 530px) {
    display: block;
    min-height: 250px;
    max-height: 250px;
    position: relative;
    flex: 1;
    > img {
      max-height: 100%;
    }
  }
`;
