import React, { memo } from "react";
import { Heading5, Small, XSmall } from "../../../typography";
import Tooltip from "../../../tooltip";

import { Row, HeaderCell, HeaderContent } from "./styles";

const TableHeader = memo(({ headerGroup, disableSortBy, columns }) => {
  return (
    <Row {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column, i) => {
        return (
          <HeaderCell
            key={i}
            sortable={!disableSortBy && column.canSort}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            <HeaderContent>
              <Heading5 color="#666666">{column.render("Header")}</Heading5>
              {column.unit ? (
                <XSmall color="#666666">{column.unit}</XSmall>
              ) : (
                ""
              )}
            </HeaderContent>
          </HeaderCell>
        );
      })}
    </Row>
  );
});

export default TableHeader;

// {column.isSorted ? (
//   column.isSortedDesc ? (
//     <ArrowDown />
//   ) : (
//     <ArrowUp />
//   )
// ) : (
//   <div></div>
// )}
