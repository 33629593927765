import React, { useEffect, useState } from "react";
import { includes, isEmpty } from "lodash";

import ContentContainer from "../../../../components/app-content-container";

// Components
import SitesTableGeomapDetails from "../../components/identify-sites-table-geomap-details";
import ErrorScreen from "../../error-screen";
import DashboardFilters from "../../components/identify-dashboard-filter";
import Aside from "../../../../components/app-aside";

import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";

import {
  getSites,
  getFavoriteSites,
  postFavoriteSites,
} from "../../../../services/insights-data-service";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../services/mixpanel-tracking";

export default function SiteSelection() {
  const [validCharts, setValidCharts] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedCohort, setSelectedCohort] = useState("cohort");

  const { subcohortList } = useIdentifySubcohortList();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex, filtersConfig } = cohortConfigs;

  const [siteList, setSiteList] = useState([]);
  const [activeSite, setActiveSite] = useState("");
  const [favoriteSitesIdList, setFavoriteSiteIdList] = useState([]);

  useEffect(async () => {
    setSiteList([]);
    setActiveSite("");
    if (!isEmpty(cohortIndex) && !isEmpty(subcohortList[selectedCohort])) {
      const response = await getSites(
        cohortIndex.index_name,
        subcohortList[selectedCohort].query
      );

      if (response.data.valid) {
        setSiteList(response.data.data.sites);
        setActiveSite(response.data.data.sites[0].id);
      }
    }
  }, [cohortIndex, subcohortList[selectedCohort]?.query]);

  useEffect(() => {
    if (cohortIndex) setSelectedCohort("cohort");
  }, [cohortIndex?.index_name]);

  useEffect(async () => {
    const response = await getFavoriteSites();
    if (response.data.valid) {
      setFavoriteSiteIdList(response.data.data);
    }
  }, []);

  const handleFavoriteSite = async (site, action) => {
    if (includes(favoriteSitesIdList, site.id)) {
      setFavoriteSiteIdList(favoriteSitesIdList.filter((id) => id !== site.id));
    } else {
      setFavoriteSiteIdList([...favoriteSitesIdList, site.id]);
    }
    const response = await postFavoriteSites(site.id);

    if (response.data.valid) {
      setFavoriteSiteIdList(response.data.data);
    }

    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Select Site - Favorite Site", {
      site: site,
      action: action,
    });
  };

  return (
    <>
      <Aside collapsible>
        <DashboardFilters
          isOpen
          selectedCohort={selectedCohort}
          setSelectedCohort={setSelectedCohort}
        />
      </Aside>
      <ContentContainer>
        {validCharts ? (
          <SitesTableGeomapDetails
            sites={siteList}
            activeSite={activeSite}
            setActiveSite={setActiveSite}
            selectedCohort={selectedCohort}
            favoriteSitesIdList={favoriteSitesIdList}
            handleFavoriteSite={handleFavoriteSite}
            setValidCharts={setValidCharts}
            setErrorMsg={setErrorMsg}
          />
        ) : (
          <ErrorScreen message={errorMsg} />
        )}
      </ContentContainer>
    </>
  );
}
