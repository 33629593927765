import React, { useState, Fragment } from "react";
import ReactDOM from "react-dom";
import { FaTimes } from "react-icons/fa";

import { Container, Footer, Content, Header, OutsideModal } from "./styles";
import Button from "../button";
import { Heading3, Heading4 } from "../typography";

const Modal = ({
  isShowing,
  hide,
  children,
  title,
  buttons,
  footer,
  hideOnClickOutside = true,
}) => {
  function clickOutsideHide(event) {
    if (hideOnClickOutside) {
      const element = event.currentTarget.dataset.id;
      if (element === "modal") {
        // click inside the content of the modal. call stopPropagation to avoid closing
        event.stopPropagation();
      } else if (element === "overlay") {
        hide();
      }
    }
  }

  function renderFooter() {
    if (!(!buttons || !footer)) {
      return null;
    }

    return (
      <Footer>
        {buttons &&
          buttons.map((button, index) => (
            <Fragment key={`modal-button-${index}`}>{button}</Fragment>
          ))}
        {footer ? footer : ""}
      </Footer>
    );
  }

  return isShowing
    ? ReactDOM.createPortal(
        <OutsideModal onClick={clickOutsideHide} data-id="overlay">
          <Container onClick={clickOutsideHide} data-id="modal">
            <Header>
              <Heading3>
                {title || ""}
                <Button variant="clear" onClick={hide} margin={0} padding={0}>
                  <FaTimes size={18} />
                </Button>
              </Heading3>
            </Header>
            <Content>{children || ""}</Content>
            {renderFooter()}
          </Container>
        </OutsideModal>,
        document.body
      )
    : "";
};

export const useModal = () => {
  const [isShowingModal, setIsShowingModal] = useState(false);

  function toggleModal() {
    setIsShowingModal(!isShowingModal);
  }

  return {
    isShowingModal,
    toggleModal,
  };
};

export default Modal;
