import { includes } from "lodash";
import React, { useEffect, useState } from "react";
import { lighten, darken } from "polished";

import ChoroplethWrapper from "../../../../components/dashboard-choropleth-wrapper";

import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";
import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useMapsTopography } from "../../../../hooks/useMapTopography";

import { getPopulationChoroplethData } from "../../../../services/insights-data-service";

function AwarePopulationGeomap({ setValidCharts, setErrorMsg }) {
  const { subcohortList, dispatch: subcohortListDispatch } =
    useIdentifySubcohortList();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex, filtersConfig } = cohortConfigs;
  const { mapsTopography } = useMapsTopography();
  const [responseList, setResponseList] = useState({});
  const [maps, setMaps] = useState([]);

  async function getMapData(cohortQuery) {
    const response = await getPopulationChoroplethData(
      cohortIndex.index_name,
      cohortQuery
    );
    if (response.data?.valid) {
      return response.data.data.result;
    }

    setValidCharts(false);
    setErrorMsg(response.data.messages.join());
    return {};
  }

  function getMapsConfig(subcohortID, data) {
    const config = {};
    const color = subcohortList[subcohortID].color;
    config.title = "Population Map";
    config.name = subcohortList[subcohortID].name;
    config.layers = data;
    config.colorPalette = [lighten(0.25, color), color, darken(0.15, color)];
    config.getTooltip = (properties, postalData) => {
      if (postalData && postalData.pop_size)
        return `${properties.name} - ${properties.postal}: ${postalData.pop_size} patients`;
      return "";
    };
    return config;
  }

  useEffect(async () => {
    const newResponseList = { ...responseList };
    let fetchedID;

    // Trigger the get Maps data for the subcohort that was changed or added
    for (const id in subcohortList) {
      if (
        !subcohortList[id].fetchedData.populationMap &&
        subcohortList[id].status !== "isCreating"
      ) {
        setMaps([]); // to assure that a loading will appear while getting the data
        const response = await getMapData(subcohortList[id].query);
        newResponseList[id] = response;
        fetchedID = id;
      } else if (subcohortList[id].fetchedData.populationMap) {
        newResponseList[id] = subcohortList[id].fetchedData.populationMap;
      }
    }

    //Treat the remove subcohorts, if removed from subcohortList will be removed from response list
    for (const id in newResponseList) {
      if (!(id in subcohortList)) delete newResponseList[id];
    }

    setResponseList(newResponseList);

    //Change the subcohort that were edited or added to loaded
    if (fetchedID)
      subcohortListDispatch({
        type: "UPDATE_SUBCOHORT_FETCHED_DATA",
        payload: {
          chart: "populationMap",
          fetchedData: newResponseList[fetchedID],
          id: fetchedID,
        },
      });
  }, [
    subcohortList.cohort?.status,
    subcohortList["subcohort-a"]?.status,
    subcohortList["subcohort-b"]?.status,
    subcohortList["subcohort-c"]?.status,
  ]);

  // When any response chnages trigger a build of the maps config structure
  useEffect(() => {
    const newMaps = [];
    Object.keys(responseList).forEach((id, i) => {
      if (id in subcohortList && subcohortList[id].visible)
        newMaps.push(getMapsConfig(id, responseList[id].population));
    });
    setMaps(newMaps);
  }, [
    responseList.cohort,
    responseList["subcohort-a"],
    responseList["subcohort-b"],
    responseList["subcohort-c"],
    subcohortList?.cohort?.visible,
  ]);

  return (
    <ChoroplethWrapper
      maps={maps}
      topography={mapsTopography}
      ratioSelect
      defaultLayer={1}
      defaultMap={maps.length - 1}
      id="populationMap"
    />
  );
}

export default AwarePopulationGeomap;
