import { includes, isEmpty, remove } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";
import Checkbox from "../../../../../../../../components/basic-inputs/checkbox";

import {
  mixpanelTrack,
  mixpanelIdentify,
} from "../../../../../../../../services/mixpanel-tracking";

import { Row } from "../styles";

function Boolean({ filter, draftFilterList, setDraftFilterList }) {
  const location = useLocation();
  const handleFilterChange = (attributeKey, value) => {
    const newFilterList = { ...draftFilterList };

    let newFilterArray;
    if (newFilterList[attributeKey]) {
      newFilterArray = [...newFilterList[attributeKey]];
      includes(newFilterArray, value)
        ? remove(newFilterArray, (n) => n === value)
        : newFilterArray.push(value);
    } else {
      newFilterArray = [value];
    }

    if (isEmpty(newFilterArray)) delete newFilterList[attributeKey];
    else newFilterList[attributeKey] = [...newFilterArray];

    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Filter Change", {
      attributeFilter: attributeKey,
      valeu: newFilterList[attributeKey],
      urlPathname: location.pathname,
    });

    setDraftFilterList(newFilterList);
  };

  return (
    <Row>
      <Checkbox
        value="true"
        label="Yes"
        name={filter.key}
        checked={
          draftFilterList && filter.key in draftFilterList
            ? includes(draftFilterList[filter.key], "true")
            : false
        }
        onChange={handleFilterChange}
      />
      <Checkbox
        value="false"
        label="No"
        name={filter.key}
        checked={
          draftFilterList && filter.key in draftFilterList
            ? includes(draftFilterList[filter.key], "false")
            : false
        }
        onChange={handleFilterChange}
      />
    </Row>
  );
}

export default Boolean;
