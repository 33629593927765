import React, { useState } from "react";
import { BsFillPeopleFill, BsPersonFill } from "react-icons/bs";
import { redirect, useHistory, useLocation } from "react-router-dom";
import Ratiobox from "../../../../../../components/basic-inputs/radiobox";
import Button from "../../../../../../components/button";

import Modal from "../../../../../../components/modal";
import InfoTooltip from "../../../../../../components/tooltip-info-icon";
import { useIdentifySimulation } from "../../../../../../hooks/useIdentifySimulation";

import { useIdentifySubcohortList } from "../../../../../../hooks/useIdentifySubcohortList";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../../services/mixpanel-tracking";

import {
  Container,
  Description,
  SubcohortOption,
  SubcohortOptionList,
} from "./styles";

function SimulationModal({ toggleModal, isShowingModal }) {
  const { subcohortList } = useIdentifySubcohortList();
  const { dispatch: simulationDispatch } = useIdentifySimulation();
  const [selectedSubcohortID, setSelectedSubcohortID] = useState("cohort");

  const location = useLocation();
  const history = useHistory();

  const modalTitle = () => {
    return (
      <InfoTooltip
        description={
          "A simulation will apply a COX proportional hazard model to a cohort or subcohort."
        }
      >
        Add simulation
      </InfoTooltip>
    );
  };

  const handleAddSimulationSubcohort = () => {
    const selectedCohort = {
      name: subcohortList[selectedSubcohortID].name,
      id: selectedSubcohortID,
      query: subcohortList[selectedSubcohortID].query,
      color: subcohortList[selectedSubcohortID].color,
      subcohortSize: subcohortList[selectedSubcohortID].subcohortSize,
      status: "isLoading",
    };
    simulationDispatch({
      type: "CHANGE_SELECTED_COHORT",
      payload: selectedCohort,
    });
    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Add Simulation", {
      selectedCohort: {
        type: "subcohort",
        name: subcohortList.name,
        query: subcohortList.query,
      },
      urlPathname: location.pathname,
    });
    toggleModal();
    history.push({ pathname: "/clint-identify/simulation" });
  };

  const getSubcohortOption = (subcohort, id) => {
    return (
      <SubcohortOption key={id}>
        {id === "cohort" ? (
          <BsFillPeopleFill size={18} />
        ) : (
          <BsPersonFill size={18} />
        )}

        <Ratiobox
          key={id}
          value={id}
          label={subcohort.name}
          name={"simulatedSubcohort"}
          checked={selectedSubcohortID === id}
          onChange={(_, value) => setSelectedSubcohortID(value)}
          ratioPosition="right"
        />
      </SubcohortOption>
    );
  };

  const getModalButtons = () => {
    const buttons = [];

    buttons.push(
      <Button variant={"outline"} onClick={toggleModal}>
        Cancel
      </Button>
    );
    buttons.push(
      <Button
        variant={"confirm"}
        onClick={() => handleAddSimulationSubcohort()}
      >
        Go
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <Modal
        title={modalTitle()}
        isShowing={isShowingModal}
        hide={toggleModal}
        buttons={getModalButtons()}
      >
        <Description>Choose the Cohort or Subcohort to simulate</Description>
        <SubcohortOptionList>
          {Object.keys(subcohortList).map((id) =>
            getSubcohortOption(subcohortList[id], id)
          )}
        </SubcohortOptionList>
      </Modal>
    </div>
  );
}

export default SimulationModal;
