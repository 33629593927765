import React from "react";
import * as d3 from "d3";
import { event as currentEvent } from "d3";
import { cloneDeep, find, unionBy, uniqBy } from "lodash";
import { Small } from "../../../../typography";

class D3BarPlotLayer extends React.Component {
  constructor(props) {
    super(props);

    this.gWrapperRef = null;
    this.setGWrapperRef = (e) => {
      this.gWrapperRef = e;
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Ensures that the component doesn't get re-rendered without reason

    // Currently, updates every time the width of height changes
    // TODO: add check for data as well
    if (
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.data !== nextProps.data ||
      this.props.normalizedData !== nextProps.normalizedData
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.data && this.props.height !== 0 && this.props.width !== 0) {
      this.renderChartD3Wrapper();
    }
  }

  componentDidUpdate() {
    d3.select(this.gWrapperRef).select("g").remove();
    if (this.props.data) {
      this.renderChartD3Wrapper();
    }
  }

  handleMouseOver(e) {
    /*
    Sets the style of the hovered bar and calls the parent function responsible for showing the
    tooltip. Once we're using the Redux store, this should probably be handled in the layout state.

    D3 mouse events are, by default, bound to the element where the action occurs (i.e, `this`
    refers to the svg element that triggered the event). To keep the React Class context for the
    `this` keyword, we bind the event handler to the `this` of the class, and to still have acess
    to the triggered mouse event, we use the D3 `currentEvent` (imported in the beginning of this
    file).
    */
    if (this.props.config.showTooltip) {
      d3.select(currentEvent.target).style("fill-opacity", "1");
      const d3Data = e;
      const category = currentEvent.target.getAttribute("data-category");

      const xLabel = this.props.meta.axesTitle.x;
      const yLabel = category || this.props.meta.axesTitle.y;

      const content = (
        <table>
          <tbody>
            <tr key="key">
              <td key={xLabel}>
                <Small>{`${xLabel}: `}</Small>
              </td>
              <td key={d3Data.key}>
                <Small>
                  <b>
                    {d3Data.key}
                    {d3Data.key_range ? ` - ${d3Data.key_range}` : ""}
                  </b>
                </Small>
              </td>
            </tr>
            <tr key="value">
              <td key={yLabel}>
                <Small>{`${yLabel}: `}</Small>
              </td>
              <td key={d3Data.value}>
                <Small>
                  <b>
                    {this.props.normalizedData &&
                    this.props.config.normalizeToogle
                      ? `${d3Data.value.toFixed(2)}%`
                      : d3Data.value}
                  </b>
                </Small>
              </td>
            </tr>
          </tbody>
        </table>
      );

      this.props.mouseOverFunc(content);
    }
  }

  handleMouseOut() {
    /*
    Re-sets the style of the bar and calls the parent function responsible for hiding the tooltip.
    Once we're using the Redux store, this should probably be handled in the layout state.
    */
    if (this.props.config.showTooltip) {
      d3.select(currentEvent.target).style("fill-opacity", "0.7");
      this.props.mouseOutFunc();
    }
  }

  renderChartD3Wrapper() {
    const { width } = this.props;
    const { height } = this.props;

    const axesType = this.props.config.axesXType;
    const { flipAxes } = this.props.config;
    // const rotateXAxisLabels = this.props.config.rotateXAxisLabels;
    const { hideVerticalAxis } = this.props.config;
    const { hideHorizontalAxis } = this.props.config;

    const { hideLegends } = this.props.config;
    const legendPosition = this.props.config.legendPosition
      ? this.props.config.legendPosition
      : "left"; // Top or left for now
    const { chartObservation } = this.props.meta;

    const barPaddingRatio = this.props.config.spacing;
    const { colorPalette } = this.props.config;
    const chartLayout = this.props.config.layout;
    const normalizeData = this.props.normalizedData;
    const { normalizeToogle } = this.props.config;
    let data = cloneDeep(this.props.data);

    if (normalizeData && normalizeToogle) {
      const normalizationSum = [];
      data.map((d) => {
        d.values.map((item) => {
          const keyAlreadyIn = find(normalizationSum, function (o) {
            return o.key == item.key;
          });
          if (!keyAlreadyIn) {
            let sum = 0;
            data.map((b) => {
              sum =
                find(b.values, function (o) {
                  return o.key == item.key;
                }).value + sum;
            });
            normalizationSum.push({ key: item.key, sum });
          }
        });
      });

      data = data.map((d, index) => {
        d.values.map((item) => {
          item.value =
            (item.value * 100) /
            find(normalizationSum, function (o) {
              return o.key == item.key;
            }).sum;
        });
        return d;
      });
    }

    data = data.map((d, index) => {
      d.values.map((item) => {
        if (index == 0 || chartLayout != "stacked") {
          item.value0 = 0;
        } else {
          item.value0 = find(data[index - 1].values, function (o) {
            return o.key == item.key;
          }).value;
        }
      });
      return d;
    });

    // The `margin` prop defines the "padding" inside the svg container. For some categorical
    // charts, we want to rotate the labels. This requires more space at the bottom
    const margin = cloneDeep(this.props.marginDefs);
    const axisMargin = cloneDeep(this.props.axisMarginDefs);
    // if (rotateXAxisLabels) margin.bottom += margin.bottomPadding;
    const horizontalMargin = axisMargin.horizontal;
    const verticalMargin = axisMargin.vertical;
    const legendVerticalSpace =
      (!hideLegends && legendPosition == "left" && data.length >= 2) ||
      chartObservation
        ? width * margin.withLegend.vertical
        : 0;
    const legendHorizontalSpace =
      !hideLegends && legendPosition == "top" && data.length >= 2
        ? margin.withLegend.horizontal
        : 0;

    const innerWidth =
      width -
      margin.left -
      margin.right -
      horizontalMargin -
      legendVerticalSpace;
    const innerHeight =
      height -
      margin.top -
      margin.bottom -
      verticalMargin -
      legendHorizontalSpace;

    // Set the Y and X Domain and the Vertical and Horizontal Ranges
    let yDomain;
    let xDomain;
    const { increaseYMaxValue } = this.props;
    const verticalRange = [innerHeight, 0];
    const horizontalRange = [0, innerWidth];

    yDomain = [
      d3.min(data, function (d) {
        return d3.min(d.values, function (b) {
          return b.value0;
        });
      }),
      d3.max(data, function (d) {
        return d3.max(d.values, function (b) {
          return b.value + b.value0;
        });
      }) * increaseYMaxValue,
    ];

    if (axesType === "continuous") {
      xDomain = [
        d3.min(data, function (d) {
          return d3.min(d.values, function (b) {
            return b.key;
          });
        }),
        d3.max(data, function (d) {
          return d3.max(d.values, function (b) {
            return b.key;
          });
        }),
      ];
    } else if (axesType === "ordinal") {
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      data.map((d) => {
        xDomain = unionBy(
          xDomain,
          d.values.map((b) => {
            return b.key;
          })
        );
      });
      xDomain = uniqBy(xDomain).sort(collator.compare);
    } else {
      console.error("Invalid Axes Type");
    }

    // D3 selects the parent group tag using the React ref and attach nested groups to it.
    // When the component is updated, children of this node are removed and re-added.
    const parentG = d3.select(this.gWrapperRef);

    const g = parentG
      .append("g")
      .attr(
        "transform",
        `translate(${margin.left + horizontalMargin},${
          margin.top + legendHorizontalSpace
        })`
      );

    // Define scales.
    let xScale;
    let yScale;
    if (flipAxes) {
      xScale = d3.scale.linear().domain(yDomain).range(horizontalRange);

      yScale = d3.scale
        .ordinal()
        .domain(xDomain)
        .rangeBands(verticalRange, barPaddingRatio);
    } else {
      yScale = d3.scale.linear().domain(yDomain).range(verticalRange);

      xScale = d3.scale
        .ordinal()
        .domain(xDomain)
        .rangeBands(horizontalRange, barPaddingRatio);
    }

    // Plot bars
    data.map((bars, index) => {
      g.selectAll("bar")
        .data(bars.values)
        .enter()
        .append("rect")
        .attr("data-category", bars.name)
        .on("mouseover", this.handleMouseOver.bind(this))
        .on("mouseout", this.handleMouseOut.bind(this))
        .style("fill", function (d) {
          if (colorPalette) {
            return colorPalette[index];
          }
          return "#1F61A2";
        })
        .style("fill-opacity", "0.7")
        .attr("x", function (d) {
          if (flipAxes) {
            return xScale(d.value0);
          }
          return xScale(d.key);
        })
        .attr("y", function (d) {
          if (flipAxes) {
            return yScale(d.key);
          }
          return yScale(d.value0 + d.value);
        })
        .attr("width", function (d) {
          if (flipAxes) {
            return xScale(d.value);
          }
          return xScale.rangeBand();
        })
        .attr("height", function (d) {
          if (flipAxes) {
            return yScale.rangeBand();
          }
          return yScale(d.value0) - yScale(d.value0 + d.value);
        })
        .style("stroke", function (d) {
          if (colorPalette) {
            return colorPalette[index];
          }
          return "#1F61A2";
        }) // Stroke color is the same as the BG color of the container.
        .style("stroke-width", function (d) {
          if (barPaddingRatio === 0) {
            return "1px";
          }
          return "0px";
        }); // If there's no spacing between the bars, add a darker stroke
    });
  }

  render() {
    return <g ref={this.setGWrapperRef} />;
  }
}

export default D3BarPlotLayer;
