import React, { useEffect, useState } from "react";
import Field from "../field";
import Operator from "../operator";
import Value from "../value";
import { RuleContainer } from "./styles";

const Rule = ({ index, rule, fields, onChange, errors = {} }) => {
  const [selectedField, setSelectedField] = useState(rule.field || "");
  const [selectedOperator, setSelectedOperator] = useState(rule.operator || "");
  const [value, setValue] = useState(rule.value || "");

  useEffect(() => {
    setSelectedField(rule.field);
    setSelectedOperator(rule.operator);
    setValue(rule.value);
  }, [rule]);

  const handleFieldChange = (field) => {
    setSelectedField(field);
    setSelectedOperator("");
    setValue("");
    onChange(index, { ...rule, field, operator: "", value: "" });
  };

  const handleOperatorChange = (operator) => {
    setSelectedOperator(operator);
    if (operator !== "") {
      onChange(index, { ...rule, operator });
    } else {
      setValue("");
      onChange(index, { ...rule, operator, value: "" });
    }
  };

  const handleValueChange = (value) => {
    setValue(value);
    onChange(index, { ...rule, value });
  };

  const getOperators = () => {
    if (!selectedField) return [];
    const fieldType = fields.find((f) => f.value === selectedField).type;
    switch (fieldType) {
      case "categorical":
        return [
          { value: "is", label: "is" },
          { value: "isnot", label: "is not" },
        ];
      case "numerical":
        return [
          { value: "eq", label: "equal to" },
          { value: "neq", label: "not equal to" },
          { value: "lt", label: "less than" },
          { value: "lte", label: "less than or equal to" },
          { value: "gt", label: "greater than" },
          { value: "gte", label: "greater than or equal to" },
        ];
      case "boolean":
        return [
          { value: "is", label: "is" },
          { value: "isnot", label: "is not" },
        ];
      default:
        return [];
    }
  };

  return (
    <RuleContainer>
      <Field
        selectedField={selectedField}
        fields={fields}
        onChange={handleFieldChange}
        error={errors.field}
      />
      <Operator
        selectedField={selectedField}
        selectedOperator={selectedOperator}
        operators={getOperators()}
        onChange={handleOperatorChange}
        error={errors.operator}
      />
      <Value
        selectedField={selectedField}
        selectedOperator={selectedOperator}
        value={value}
        fields={fields}
        onChange={handleValueChange}
        error={errors.value}
      />
    </RuleContainer>
  );
};

export default Rule;
