import React from "react";
import RuleGroup from "./rule-group";

const QueryViewer = ({ fields, query }) => {
  const renderRuleGroup = (group, groupIndex = []) => (
    <RuleGroup
      key={groupIndex.join("-")}
      id={groupIndex.join("-")}
      rules={group.rules}
      combinator={group.combinator}
      fields={fields}
    />
  );

  return renderRuleGroup(query);
};

export default QueryViewer;
