import React from "react";
import "@babel/polyfill";
import { render } from "react-dom";
import { Provider } from "react-redux";

import SessionService from "./services/session-service.js";
import Actions from "./actions/actions.js";
import store from "./store";
import Routes from "./routes";

import { BrowserRouter, Route } from "react-router-dom";
import createReactClass from "create-react-class";

import { TypographyStyle } from "./styles/typography";
import { ResetStyle } from "./styles/reset";

import { init } from "@fullstory/browser";

init({ orgId: "o-1ZPYQV-na1" });

// Main app component
var App = createReactClass({
  componentWillReceiveProps(nextProps) {
    const locationChanged = nextProps.location !== this.props.location;
    if (locationChanged) {
      let PreviousUrl =
        this.props.location.pathname +
        this.props.location.search +
        this.props.location.hash;
      let NextUrl =
        nextProps.location.pathname +
        nextProps.location.search +
        nextProps.location.hash;
      store.dispatch({
        type: "CHANGE_ROUTE",
        selectedRoute: NextUrl,
        previous: PreviousUrl,
      });
    }
  },

  render: function () {
    let state = store.getState();
    //console.log(state)
    return <Routes />;
  },
});

// Render complete app everytime store changes
const renderAll = () => {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <ResetStyle />
        <TypographyStyle />
      </BrowserRouter>
    </Provider>,
    document.getElementById("app")
  );
};

// Render an instance of App into div id #app
store.subscribe(renderAll);

if (SessionService.sessionIsAuthenticated()) {
  Actions.initializeAppState(store);
} else {
  // When app state is created for the first time, initialize redux-store.
  store.dispatch({ type: "INITIALIZE_APP" });
}
