import styled, { css } from "styled-components";

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  margin: -16px;
`;

export const SubcohortName = styled.div`
  font-weight: 500;
`;

export const HeaderWrapper = styled.div`
  margin: 8px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditButton = styled.button`
  background-color: transparent;

  color: #20769a;
  font-size: 12px;
  font-weight: 400;

  cursor: pointer;

  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  border-radius: 3px;
  padding: 8px 4px;
  border: 1px solid #20769a;

  &:hover {
    background-color: #effbfc;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: #727b8b;
      border-color: #edf0f2;

      &:hover {
        background-color: transparent;
      }
    `}
`;
