import styled, { css } from "styled-components";

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  line-height: 1.4;

  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: 1;
  }

  background: #f2f2f2;
  color: #26344c;
  border: 1px solid #8f9cb3;

  > a {
    text-decoration: unset;
    color: #26344c;
  }

  max-height: 30px;
  height: 30px;

  ${(props) =>
    "margin" in props
      ? css`
          margin: ${props.margin};
        `
      : css`
          margin-left: 8px;
          margin-bottom: 5px;
        `}

  ${(props) =>
    "padding" in props
      ? css`
          madding: ${props.padding};
        `
      : css`
          padding: 5px 10px;
        `}

  ${(props) =>
    props.size === "sm" &&
    css`
      padding: 2px 5px 1px;
      font-weight: 200;
      font-size: 12px;
      height: 20px;
    `}

  ${(props) =>
    props.size === "xs" &&
    css`
      padding: 2px 20px 1px;
      font-weight: 100;
      font-size: 12px;
      height: 20px;
    `}

  ${(props) =>
    props.variant === "confirm" &&
    css`
      border: 0;
      background: #26344d;
      color: #ffffff;

      &:hover {
        opacity: 0.9;
      }

      > a {
        color: #ffffff;
      }

      /* confirm variant missing hover and active*/
    `}

  ${(props) =>
    props.variant === "warning" &&
    css`
      background: #e3bc59;
      border: 1px solid #e3bc59;
      color: #26344d;

      > a {
        color: #26344c;
      }

      /* warning variant missing hover and active*/
    `}

  ${(props) =>
    props.variant === "danger" &&
    css`
      background-color: #b4436c;
      border: 1px solid #b4436c;
      color: #ffffff;

      > a {
        color: #fff;
      }

      /* danger variant missing hover and active*/
    `}

  ${(props) =>
    props.variant === "link" &&
    css`
      background: transparent;
      border: 1px solid transparent;
      color: #1f61a2;
      text-decoration: underline;

      /* link variant missing hover and active*/
    `}

  ${(props) =>
    props.variant === "clear" &&
    css`
      background: transparent;
      border: 1px solid transparent;
      color: inherit;

      &:hover {
        background-color: #c1c8d4;
      }
      /* clear variant missing active*/
    `}

  ${(props) =>
    props.variant === "outline" &&
    css`
      background: transparent;
      color: #26344d;
      border: 1px solid #26344d;

      > a {
        color: #26344d;
      }

      &:hover {
        background-color: #c1c8d4;
      }

      /* outline variant missing hover and active*/
    `}

  ${(props) =>
    props.variant === "outlineDanger" &&
    css`
      background: transparent;
      color: #b4436c;
      border: 1px solid #b4436c;

      > a {
        color: #b4436c;
      }

      &:hover {
        background-color: #c1c8d4;
      }

      /* outline variant missing hover and active*/
    `}

  &[disabled],&[disabled]:hover {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const LeftIcon = styled.div`
  margin-right: 5px;
  margin-bottom: 2px;
`;
export const RightIcon = styled.div`
  margin-left: 5px;
  margin-bottom: 2px;
`;
