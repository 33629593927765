import styled, { css } from "styled-components";

export const Row = styled.tr``;

export const HeaderCell = styled.th`
  text-align: left;
  cursor: default;

  /* All Cells */
  padding: 8px 8px 8px 12px;
  border-bottom: 1px solid var(--primary);
  vertical-align: bottom;
  position: relative;

  ${(props) =>
    props.sortable &&
    css`
      cursor: pointer;
      &:before,
      &:after {
        border: 3px solid transparent;
        content: "";
        display: block;
        height: 0;
        left: 0px;
        bottom: 20px;
        position: absolute;
        width: 0;
      }
      &:before {
        border-bottom-color: #666666;
        margin-bottom: -1px;
      }
      &:after {
        border-top-color: #666666;
        margin-bottom: -10px;
      }
    `}
`;

export const HeaderContent = styled.div`
  width: 100%;
  position: relative;
`;
