import React, { useMemo } from "react";
import { OperatorContainer } from "./styles";
import SelectInput from "../../basic-inputs/select";
import { XSmall } from "../../typography";
import { isUndefined } from "lodash";

const Operator = ({
  selectedField,
  selectedOperator,
  operators,
  onChange,
  error,
}) => {
  const options = useMemo(() => {
    let newOperators = [...operators];
    newOperators.unshift({ value: "", label: "Choose operator" });
    return newOperators;
  }, [operators]);

  return (
    <OperatorContainer>
      <SelectInput
        value={selectedOperator}
        onChange={onChange}
        options={options}
        disabled={!selectedField}
        marginBottom={0}
        invalid={!isUndefined(error)}
      ></SelectInput>
      {error ? (
        <XSmall color="#B4436C" marginTop={1}>
          {error}
        </XSmall>
      ) : (
        ""
      )}
    </OperatorContainer>
  );
};

export default Operator;
