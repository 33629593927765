import React from "react";
import { useMemo } from "react";
import { RuleContainer, Field, Value, Operator, NotDefined } from "./styles";

const Rule = ({ rule, fields }) => {
  const selectedField = useMemo(() => {
    return fields.find((f) => f.value === rule.field);
  }, [rule.field]);

  const selectedOperator = useMemo(() => {
    if (!rule.field) return null;
    const fieldType = selectedField.type;
    let availableOptions = [];
    switch (fieldType) {
      case "categorical":
        availableOptions = [
          { value: "is", label: "is" },
          { value: "isnot", label: "is not" },
        ];
        break;
      case "numerical":
        availableOptions = [
          { value: "eq", label: "equal to" },
          { value: "neq", label: "not equal to" },
          { value: "lt", label: "less than" },
          { value: "lte", label: "less or equal to" },
          { value: "gt", label: "greater than" },
          { value: "gte", label: "greater or equal to" },
        ];
        break;
      case "boolean":
        availableOptions = [
          { value: "is", label: "is" },
          { value: "isnot", label: "is not" },
        ];
        break;
    }
    return availableOptions.find((f) => f.value === rule.operator);
  }, [rule.operator]);

  return (
    <RuleContainer>
      <Field>{selectedField?.label}</Field>
      {selectedOperator ? (
        <Operator>{selectedOperator.label}</Operator>
      ) : (
        <NotDefined>*Operator not defined*</NotDefined>
      )}
      {rule.value ? (
        <Value>{rule.value}</Value>
      ) : (
        <NotDefined>*Value not defined*</NotDefined>
      )}
    </RuleContainer>
  );
};

export default Rule;
