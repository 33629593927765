import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { filter } from "lodash";

import { XSmall } from "../../../../../../components/typography";

import Alert from "../../../../../../components/message-alert";
import Radiobox from "../../../../../../components/basic-inputs/radiobox";

import {
  SubcohortItem,
  TableContainer,
  EmptyItem,
  Content,
  Footer,
  ContentPadding,
} from "./styles";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../../services/mixpanel-tracking";

import Button from "../../../../../../components/button";
import SearchInput from "../../../../../../components/basic-inputs/input/search-input";

import { useIdentifyCohortConfigs } from "../../../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../../../hooks/useIdentifySubcohortList";
import {
  getSavedSubcohort,
  deleteSavedSubcohort,
} from "../../../../../../services/insights-data-service";

function SavedsubcohortList({
  selectedCohort,
  setSelectedCohort,
  subcohortID,
  isEditing,
  subcohort,
}) {
  const { cohortConfigs, dispatch: cohortConfigDispatch } =
    useIdentifyCohortConfigs();
  const { dispatch: subcohortListDispatch } = useIdentifySubcohortList();
  const { cohortIndex, savedSubcohorts } = cohortConfigs;

  const location = useLocation();

  const [messageAlertConfig, setMessageAlertConfig] = useState({});
  const [searchSubcohort, setSearchSubcohort] = useState("");
  const [selectedSavedSubcohortIndex, setSelectedSavedSubcohortIndex] =
    useState("");

  useEffect(() => {
    if (messageAlertConfig.timeout)
      setTimeout(() => {
        setMessageAlertConfig({});
      }, messageAlertConfig.timeout);
  }, [messageAlertConfig]);

  const handleApplySubcohort = () => {
    const savedSubcohort = filteredSubcohort[selectedSavedSubcohortIndex];
    if (savedSubcohort) {
      subcohortListDispatch({
        type: "APPLY_SUBCOHORT",
        payload: {
          id: subcohortID,
          query: savedSubcohort.query,
          name: savedSubcohort.subcohort_name,
          isSaved: true,
          isAdvanced: true,
        },
      });

      if (selectedCohort) setSelectedCohort(subcohortID);

      mixpanelIdentify(localStorage.email);
      mixpanelTrack("Apply Subcohort", {
        cohort: cohortIndex.index_name,
        subcohort: savedSubcohort.subcohort_name,
        query: savedSubcohort.query,
        urlPathname: location.pathname,
      });
    }
  };

  const deleteSubcohort = async () => {
    const savedSubcohort = filteredSubcohort[selectedSavedSubcohortIndex];
    if (savedSubcohort) {
      // eslint-disable-next-line
      const alertResponse = window.confirm(
        `Do you really want to delete ${cohortIndex.index_human_readable_name}'s subcohort: ${savedSubcohort.subcohort_name} ?`
      );

      if (alertResponse === true) {
        const response = await deleteSavedSubcohort(
          cohortIndex.index_name,
          savedSubcohort.subcohort_name
        );

        if (response.data.valid) {
          mixpanelIdentify(localStorage.email);
          mixpanelTrack("Delete Subcohort", {
            cohort: cohortIndex.index_name,
            subcohort: savedSubcohort.subcohort_name,
            query: savedSubcohort.query,
            urlPathname: location.pathname,
          });

          const newSavedSubcohorts = await getSavedSubcohort(
            cohortIndex.index_name
          );

          cohortConfigDispatch({
            type: "CHANGE_COHORT_SAVED_SUBCOHORT_LIST",
            payload: newSavedSubcohorts,
          });

          setMessageAlertConfig({
            timeout: 5000,
            message: `The ${cohortIndex.index_human_readable_name}'s subcohort: ${savedSubcohort.subcohort_name} was deleted with success`,
            variant: "success",
          });
          setSelectedSavedSubcohortIndex(-1);
        } else {
          setMessageAlertConfig({
            timeout: 5000,
            message: `Something went wrong. We apologize for the inconvenience. Please try again later`,
            variant: "warning",
          });
        }
      }
    }
  };

  const filteredSubcohort =
    searchSubcohort.length === 0
      ? savedSubcohorts
      : filter(
          savedSubcohorts,
          (savedSubcohort) =>
            savedSubcohort.subcohort_name.search(
              new RegExp(searchSubcohort, "i")
            ) > -1
        );

  const getSubcohortList = () => {
    let content = (
      <EmptyItem>No saved subcohorts for the selected cohort</EmptyItem>
    );

    if (filteredSubcohort.length) {
      content = filteredSubcohort.map((savedSubcohort, index) => {
        const subcohortLabel = (
          <Radiobox
            key={index}
            value={index}
            label={savedSubcohort.subcohort_name}
            name={"savedSubcohort"}
            disabled={subcohort.name === savedSubcohort.subcohort_name}
            checked={selectedSavedSubcohortIndex === index}
            onChange={(_, value) => setSelectedSavedSubcohortIndex(+value)}
          />
        );
        return <SubcohortItem key={index}>{subcohortLabel}</SubcohortItem>;
      });
    }

    return <TableContainer>{content}</TableContainer>;
  };

  return (
    <>
      {messageAlertConfig.message ? (
        <Alert
          variant={messageAlertConfig.variant}
          timeout={messageAlertConfig.timeout}
        >
          {messageAlertConfig.message}
        </Alert>
      ) : (
        ""
      )}
      <ContentPadding>
        <SearchInput
          onChange={(value) => setSearchSubcohort(value)}
          placeholder="Search subcohort"
          value={searchSubcohort}
          resetSearch={() => setSearchSubcohort("")}
          style={{ flex: 1 }}
        />
      </ContentPadding>

      <Content>{cohortIndex && getSubcohortList(cohortIndex)}</Content>

      <Footer>
        <Button
          variant="confirm"
          onClick={() => handleApplySubcohort()}
          disabled={selectedSavedSubcohortIndex === ""}
        >
          {isEditing ? "Update dashboard" : "Apply to dashboard"}
        </Button>
        <Button
          variant="outline"
          onClick={() => deleteSubcohort()}
          disabled={selectedSavedSubcohortIndex === ""}
          margin={0}
        >
          Delete
        </Button>
      </Footer>
    </>
  );
}

export default SavedsubcohortList;
