import React, { useRef, useEffect, useState } from "react";
import { groupBy, filter, isEmpty, isEqual } from "lodash";
import { BsChevronContract, BsChevronExpand } from "react-icons/bs";
import { MdArrowForwardIos } from "react-icons/md";

import { useLocation } from "react-router-dom";

import { Footer, Content, SearchWrapper, LinkAQB } from "./styles";

import Button from "../../../../../../components/button";
import SearchInput from "../../../../../../components/basic-inputs/input/search-input";

import FilterGroup from "./filter-group";

import { Small } from "../../../../../../components/typography";

import { useIdentifyCohortConfigs } from "../../../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../../../hooks/useIdentifySubcohortList";

import DashboardHelper from "../../../../../../helpers/dashboard-helper";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../../services/mixpanel-tracking";
import { getFieldValuesFromQuery } from "../../../../../../components/advanced-query-builder/helpers";

function NewSubcohort({
  selectedCohort,
  setSelectedCohort,
  subcohort,
  subcohortID,
  isEditing,
  draftFilterList,
  setDraftFilterList,
  toggleAQBModal,
}) {
  const contentRef = useRef(null);
  const categoryCollapsibleRefs = useRef({});
  const location = useLocation();

  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { filtersConfig } = cohortConfigs;
  const { dispatch: subcohortListDispatch } = useIdentifySubcohortList();

  const [searchAttributeValue, setSearchAttributeValue] = useState("");
  const [expandAllCollapsibleToogle, setExpandAllCollapsibleToogle] =
    useState(false);

  const openAllCategories = () => {
    setExpandAllCollapsibleToogle(true);
    Object.keys(categoryCollapsibleRefs.current).map((key) =>
      categoryCollapsibleRefs.current[key]
        ? categoryCollapsibleRefs.current[key].open()
        : null
    );
  };

  const closeAllCategories = () => {
    setExpandAllCollapsibleToogle(false);
    Object.keys(categoryCollapsibleRefs.current).map((key) =>
      categoryCollapsibleRefs.current[key]
        ? categoryCollapsibleRefs.current[key].close()
        : null
    );
  };

  function handleApplyFilters() {
    subcohortListDispatch({
      type: "APPLY_SUBCOHORT",
      payload: {
        id: subcohortID,
        name: subcohortID
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase()),
        query: DashboardHelper.buildQueryFromFilters(
          draftFilterList,
          filtersConfig
        ),
        isSaved: false,
        isAdvanced: false,
      },
    });

    if (selectedCohort) setSelectedCohort(subcohortID);

    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Applied Filters", {
      filtersApplied: draftFilterList,
      urlPathname: location.pathname,
    });
  }

  useEffect(() => {
    setSearchAttributeValue("");
    if (!isEmpty(filtersConfig)) {
      Object.keys(filtersByGroup).forEach((key) => {
        categoryCollapsibleRefs.current[key] = null;
      });
    }
  }, [filtersConfig]);

  const filtersByGroup =
    searchAttributeValue.length === 0
      ? groupBy(filtersConfig, "group")
      : groupBy(
          filter(
            filtersConfig,
            (attribute) =>
              attribute.label.search(new RegExp(searchAttributeValue, "i")) > -1
          ),
          "group"
        );

  function getFilterGroups() {
    if (filtersConfig && !(Object.keys(filtersConfig).length === 0)) {
      const groups = Object.keys(filtersByGroup);
      return groups.length > 0 ? (
        groups.map((group) => (
          <FilterGroup
            key={group}
            filters={filtersByGroup[group]}
            name={group}
            subcohort={subcohort}
            subcohortID={subcohortID}
            draftFilterList={draftFilterList}
            setDraftFilterList={setDraftFilterList}
            ref={(ref) => (categoryCollapsibleRefs.current[group] = ref)}
          />
        ))
      ) : (
        <Small color={"#6b7c99"}>
          Your search didn’t match any items, please try again
        </Small>
      );
    }
    return "";
  }

  return (
    <>
      <LinkAQB onClick={toggleAQBModal}>
        Advanced filters <MdArrowForwardIos />
      </LinkAQB>
      <SearchWrapper>
        <SearchInput
          onChange={(value) => {
            if (value.length > 0) openAllCategories();
            else closeAllCategories();
            setSearchAttributeValue(value);
          }}
          placeholder="Search filters"
          value={searchAttributeValue}
          resetSearch={() => {
            closeAllCategories();
            setSearchAttributeValue("");
          }}
          marginBottom={0}
        />
        {expandAllCollapsibleToogle ? (
          <BsChevronContract
            style={{ cursor: "pointer" }}
            size={16}
            onClick={closeAllCategories}
          />
        ) : (
          <BsChevronExpand
            style={{ cursor: "pointer" }}
            size={16}
            onClick={openAllCategories}
          />
        )}
      </SearchWrapper>

      <Content ref={contentRef}>{getFilterGroups()}</Content>
      <Footer>
        <Button
          variant="confirm"
          onClick={() => handleApplyFilters()}
          disabled={
            isEmpty(draftFilterList) ||
            isEqual(draftFilterList, getFieldValuesFromQuery(subcohort.query))
          }
          margin={0}
        >
          {isEditing ? "Update dashboard" : "Apply to dashboard"}
        </Button>
      </Footer>
    </>
  );
}

export default NewSubcohort;
