import React, { useEffect, useState } from "react";
import DashboardHelper from "../../../../helpers/dashboard-helper";

// Components
import ContentContainer from "../../../../components/app-content-container";
import InfoTooltip from "../../../../components/tooltip-info-icon";
import { Heading3 } from "../../../../components/typography";
import Panel from "../../../../components/panel";
import SimulationCharts from "../../components/simulation-charts";
import Select from "../../../../components/basic-inputs/select";
import { useModal } from "../../../../components/modal";
import SimulationEditModal from "../../components/simulation-settings-modal";
import Aside from "../../../../components/app-aside";
import SimulationAside from "../../components/simulation-dashboard-sidebar";

import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySimulation } from "../../../../hooks/useIdentifySimulation";

import { getBaseAdoptionRate } from "../../../../services/insights-data-service";

import { Container, Header, SpaceBetweenRow, CenterRow } from "./styles";
import { MdEdit } from "react-icons/md";
import Tooltip from "../../../../components/tooltip";
import Breadcrumbs from "../../../../components/breadcrumbs";

export default function SimulationPage() {
  const { simulation, dispatch } = useIdentifySimulation();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex, simulationsConfig } = cohortConfigs;

  const [selectedEventType, setSelectedEventType] = useState(0);
  const { toggleModal, isShowingModal } = useModal();

  const [validCharts, setValidCharts] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(async () => {
    if (cohortIndex && simulationsConfig) {
      const response = await getBaseAdoptionRate(
        cohortIndex.index_name,
        simulation.selectedCohort.query,
        simulationsConfig[0].table1_config
      );

      if (response.data.valid)
        dispatch({
          type: "CHANGE_BASE_ADOPTION_RATE",
          payload: response.data.data.result,
        });
    }
  }, [simulation.feature, simulation.selectedCohort, simulationsConfig]);

  const eventTypesOptions = simulationsConfig.map((option, index) => ({
    value: index,
    label: option.name,
  }));

  const pathList = [
    {
      label: "Verify",
      url: "verify",
    },
    {
      label: "Simulation",
      url: "simulation",
    },
  ];

  return (
    <>
      <Aside collapsible>
        <SimulationAside />
      </Aside>
      <ContentContainer>
        <div>
          <Header>
            <Breadcrumbs pathList={pathList} />
            <SpaceBetweenRow>
              <Heading3>
                Simulating: <b>{simulation.simulationAdoptionRate}% adoption</b>{" "}
                of{" "}
                <b>
                  {DashboardHelper.showEllipsisLabel(
                    simulation.feature.label,
                    44
                  )}
                </b>{" "}
                <Tooltip description="Edit simulation">
                  <MdEdit
                    color="#6B7C99"
                    size={16}
                    style={{ cursor: "pointer", marginLeft: 5 }}
                    onClick={toggleModal}
                  />
                </Tooltip>
              </Heading3>
              <CenterRow>
                <InfoTooltip
                  description={"Choose the category of event to simulate"}
                >
                  <span style={{ whiteSpace: "nowrap" }}>Event Type</span>
                </InfoTooltip>
                <Select
                  key="eventTypedropdown"
                  options={eventTypesOptions}
                  value={selectedEventType}
                  onChange={(value) => {
                    setSelectedEventType(value);
                  }}
                  marginBottom={0}
                />
              </CenterRow>
            </SpaceBetweenRow>
          </Header>
          <Panel>
            <SimulationCharts
              selectedEventType={selectedEventType}
              setValidCharts={setValidCharts}
              setErrorMsg={setErrorMsg}
            />
          </Panel>
        </div>
        <SimulationEditModal
          toggleModal={toggleModal}
          isShowingModal={isShowingModal}
        />
      </ContentContainer>
    </>
  );
}
