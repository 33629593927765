import React, { useMemo } from "react";
import { hasCompleteRule } from "../../advanced-query-builder/helpers";
import Rule from "../rule";
import {
  RuleGroupContainer,
  RuleWrapper,
  GroupWrapper,
  RuleGroupHeader,
  RuleGroupContent,
  RuleContainer,
  CombinatorButton,
} from "./styles";

const RuleGroup = ({ id, rules, combinator, fields }) => {
  const groupIndexArray = useMemo(() => {
    return [...id.split("-").slice(1).map(Number)];
  }, [id]);

  const groupName = useMemo(() => {
    switch (groupIndexArray.length) {
      case 0:
        return "";
      case 1:
        return `CRITERIA`;
      case 2:
        return `Sub-criteria`;
      default:
        return `Group`;
    }
  }, [groupIndexArray]);

  return (
    <RuleGroupContainer groupDepth={groupIndexArray.length}>
      {groupIndexArray.length == 0 ? (
        ""
      ) : (
        <RuleGroupHeader groupDepth={groupIndexArray.length}>
          {groupName}
        </RuleGroupHeader>
      )}
      {rules.length > 0 ? (
        <RuleGroupContent>
          {rules.map((rule, index) =>
            "rules" in rule &&
            hasCompleteRule({
              combinator: rule.combinator,
              rules: rule.rules,
            }) ? (
              <GroupWrapper>
                {index > 0 ? (
                  <CombinatorButton
                    type={groupIndexArray.length == 0 ? "criteria" : "group"}
                  >
                    {combinator.toUpperCase()}
                  </CombinatorButton>
                ) : (
                  ""
                )}
                <RuleGroup
                  key={`${id}-${index}`}
                  id={`${id}-${index}`}
                  rules={rule.rules}
                  combinator={rule.combinator}
                  fields={fields}
                />
              </GroupWrapper>
            ) : "field" in rule && rule.field !== "" ? (
              <RuleWrapper>
                {index > 0 ? (
                  <CombinatorButton type="rule">
                    {combinator.toUpperCase()}
                  </CombinatorButton>
                ) : (
                  ""
                )}
                <RuleContainer key={`${id}-${index}`}>
                  <Rule index={index} rule={rule} fields={fields} />
                </RuleContainer>
              </RuleWrapper>
            ) : (
              ""
            )
          )}
        </RuleGroupContent>
      ) : (
        ""
      )}
    </RuleGroupContainer>
  );
};

export default RuleGroup;
