import React, { useState, useMemo } from "react";
import { MdStarOutline, MdStar, MdLocationPin } from "react-icons/md";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { includes, isEmpty } from "lodash";

import FilteredTable from "../../../../components/tables/filtered-table";
import KebabMenu from "../../../../components/kebab-menu";
import DownloadCsvMenuItem from "../../../../components/download-csv-menu-item";
import SitesGeomapDetails from "./site-geomap-details";
import Loader from "../../../../components/loader";
import SearchInput from "../../../../components/basic-inputs/input/search-input";
import Collapsible from "../../../../components/collapsible";
import InfoModal from "../../../../components/info-modal";
import Select from "../../../../components/basic-inputs/searchable-select";

import {
  CellElement,
  CollapsiblesContainer,
  DetailsContainer,
  Panel,
  SitesTable,
  TableContainer,
  Header,
  PanelTitleContainer,
  ActionsContainer,
  DrillDownSelect,
  BackLink,
} from "./styles";

import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../services/mixpanel-tracking";

import { getSitesCsvString } from "./convert-csv";
import { useModal } from "../../../../components/modal";
import { Heading3, Heading4, Small } from "../../../../components/typography";

export const SITE_COLUMNS_LABELS = {
  study_site_display_id: "ID",
  institution: "Name",
  institution_type: "Institution Type",
  eligible_patients: "Eligible Patients",
  zip3_eligibility: "Eligibility",
  zip3_eligible_high_event_risk: "Eligible High Risk",
  zip3_eligible_med_event_risk: "Eligible Moderate Risk",
  zip3_eligible_low_event_risk: "Eligible Low Risk",
  estimated_enrollment_score: "Enrollment",
  irb_type: "IRB Type",
  estimated_start_up_time: "Start-Up Time",
  center_size: "Size",
  has_ct_scanner: "CT scan",
  pi_name: "Physician",
  site_name: "Site",
  patient_count: "Pt. Count",
  clint_network: "Clint In-Network",
  primary_site_type: "Site Status",
  num_patients__eos_gt_250: "Est. Pts with EOS>250", //N at site
  num_referral_sites: "Ref. Physicians", //N total
  num_patients_referral_sites: "Pts across Ref. Physicians", //N total
  num_patients_referral_sites_eos_gt_250:
    "Est. Pts w/ EOS>250 across Ref. Physicians", //N total
  dist_from_source_mi: "Distance from site",
  site_key: "Associate Site",
  city: "City",
  county: "County",
  address: "Address",
  state: "State",
  zipcode: "Zipcode",
  ascvd_7point5_to_20_percent: "10-year ASCVD Risk 7.5% - 20%",
  ascvd_over_20_percent: "10-year ASCVD Risk >20%",
  ldl_70_to_100_mg_per_dl: "LDL Cholesterol 70 - 100 mg/dL",
  ldl_100_to_190_mg_per_dl: "LDL Cholesterol 100 - 190 mg/dL",
  pts_with_0_risk_factors: "Patients with 0 Risk factors",
  pts_with_1_risk_factors: "Patients with 1 Risk factors",
  pts_with_2_risk_factors: "Patients with 2 Risk factors",
  pts_with_3_risk_factors: "Patients with 3 Risk factors",
  site_score: "Site Score",
};

const DETAILS_KEYS = ["trial", "data_source"];

export default function SiteSelectionTable({
  sites,
  activeSite,
  setActiveSite,
  selectedCohort,
  favoriteSitesIdList,
  handleFavoriteSite,
  setValidCharts,
  setErrorMsg,
}) {
  const [siteSearchValue, setSiteSearchValue] = useState("");
  const [filteredSites, setFilteredSites] = useState([]);
  const [drilldownSite, setDrilldownSite] = useState("");

  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { sitesConfig, cohortIndex } = cohortConfigs;
  const { subcohortList } = useIdentifySubcohortList();

  const handleActiveSite = (site) => {
    setActiveSite(site.id);

    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Select Site - Active Site from Table", {
      site: site,
    });
  };

  const sitesColumnsKeys = useMemo(() => {
    if (sites && sites.length > 0) return Object.keys(sites[0]);
  }, [sites]);

  const siteTableInfo = useMemo(() => {
    if (sitesConfig) {
      const compositeEndpoints = {};
      const tooltips = {};
      for (const config of sitesConfig.sites_table ?? []) {
        if (!("description" in config)) {
          continue;
        }
        const title = config.unit
          ? `${config.display_name} (${config.unit})`
          : config.display_name;
        compositeEndpoints[title] = config.description;
        tooltips[config.field_name] = config.description;
      }

      const details = new Map();
      if ("details" in sitesConfig) {
        for (const detail of sitesConfig.details) {
          details.set(detail.name, detail.value);
        }
      } else {
        for (const key of DETAILS_KEYS) {
          if (key in sitesConfig) {
            details.set(capitalizeFirstLetter(key), sitesConfig[key]);
          }
        }
      }

      return {
        title: "Table Information",
        compositeEndpoints,
        details,
        tooltips,
      };
    }
    return null;
  }, [sitesConfig]);

  const columnsDefaultSort = useMemo(() => {
    const newColumnsDefaultSort = [];
    if (includes(sitesColumnsKeys, "site_status"))
      newColumnsDefaultSort.push({ id: "site_status", desc: true });

    if (includes(sitesColumnsKeys, "center_size"))
      newColumnsDefaultSort.push({ id: "center_size", desc: true });

    return newColumnsDefaultSort;
  }, [sitesColumnsKeys]);

  const sitesOptions = useMemo(() => {
    let newSitesOptions = [];
    if (includes(sitesColumnsKeys, "site_key")) {
      sites.forEach((site) => {
        if (site["site_key"] && site["is_primary_site"])
          newSitesOptions.push({
            value: site["site_key"],
            label: `${site["pi_name"]} at ${site["site_key"]}`,
          });
      });
    }
    return newSitesOptions;
  }, [sitesColumnsKeys]);

  const tableData = useMemo(() => {
    return sites.map((site) => {
      const newSite = {
        ...site,
        activeSite: site.id === activeSite,
        favorite: includes(favoriteSitesIdList, site.id)
          ? "Favorite"
          : "Non-Favorite",
      };

      if (site.site_score) newSite["zip3_score"] = site.site_score;

      if (site.pi_last && site.pi_first)
        newSite["pi_name"] = `${site.pi_last}, ${site.pi_first}`;

      return newSite;
    });
  }, [sites, activeSite, favoriteSitesIdList]);

  const tableDataFiltered = useMemo(() => {
    if (includes(sitesColumnsKeys, "site_key") && tableData.length > 0) {
      if (drilldownSite !== "") {
        return tableData
          .filter((site) => site.site_key === drilldownSite)
          .map((site) => ({
            id: site.id,
            activeSite: site.id === activeSite,
            favorite: includes(favoriteSitesIdList, site.id)
              ? "Favorite"
              : "Non-Favorite",
            associated_locations: site.associated_locations,
            eosinophil__gte_0_lt_250: site.eosinophil__gte_0_lt_250,
            eosinophil_estimate__gte_0_lt_250:
              site.eosinophil_estimate__gte_0_lt_250,
            eosinophil__gte_250_lt_300: site.eosinophil__gte_250_lt_300,
            eosinophil_estimate__gte_250_lt_300:
              site.eosinophil_estimate__gte_250_lt_300,
            eosinophil__gte_300_lt_1500: site.eosinophil__gte_300_lt_1500,
            eosinophil_estimate__gte_300_lt_1500:
              site.eosinophil_estimate__gte_300_lt_1500,
            eosinophil__gte_1500: site.eosinophil__gte_1500,
            eosinophil_estimate__gte_1500: site.eosinophil_estimate__gte_1500,
            eosinophil__null: site.eosinophil__null,
            latitude: site.latitude,
            longitude: site.longitude,
            address: site.address,
            pi_name: site.pi_name,
            site_name: site.site_name,
            patient_count: site.patient_count,
            clint_network: site.clint_network,
            primary_site_type: site.primary_site_type,
            dist_from_source_mi: site.dist_from_source_mi,
            site_key: site.site_key,
          }));
      } else {
        return tableData
          .filter((site) => site.is_primary_site === true)
          .map((site) => ({
            id: site.id,
            activeSite: site.id === activeSite,
            favorite: includes(favoriteSitesIdList, site.id)
              ? "Favorite"
              : "Non-Favorite",
            associated_locations: site.associated_locations,
            eosinophil__gte_0_lt_250: site.eosinophil__gte_0_lt_250,
            eosinophil_estimate__gte_0_lt_250:
              site.eosinophil_estimate__gte_0_lt_250,
            eosinophil__gte_250_lt_300: site.eosinophil__gte_250_lt_300,
            eosinophil_estimate__gte_250_lt_300:
              site.eosinophil_estimate__gte_250_lt_300,
            eosinophil__gte_300_lt_1500: site.eosinophil__gte_300_lt_1500,
            eosinophil_estimate__gte_300_lt_1500:
              site.eosinophil_estimate__gte_300_lt_1500,
            eosinophil__gte_1500: site.eosinophil__gte_1500,
            eosinophil_estimate__gte_1500: site.eosinophil_estimate__gte_1500,
            eosinophil__null: site.eosinophil__null,
            latitude: site.latitude,
            longitude: site.longitude,
            address: site.address,
            pi_name: site.pi_name,
            site_name: site.site_name,
            patient_count: site.patient_count,
            clint_network: site.clint_network,
            primary_site_type: site.primary_site_type,
            num_patients__eos_gt_250: site.num_patients__eos_gt_250,
            num_referral_sites: site.num_referral_sites,
            num_patients_referral_sites: site.num_patients_referral_sites,
            num_patients_referral_sites_eos_gt_250:
              site.num_patients_referral_sites_eos_gt_250,
            dist_from_source_mi: site.dist_from_source_mi,
            is_primary_site: site.is_primary_siteis_primary_site,
            site_key: site.site_key,
          }));
      }
    }
    return tableData;
  }, [
    drilldownSite,
    sitesColumnsKeys,
    tableData,
    activeSite,
    favoriteSitesIdList,
  ]);

  const sitesFilteredColumnsKeys = useMemo(() => {
    if (tableDataFiltered && tableDataFiltered.length > 0)
      return Object.keys(tableDataFiltered[0]);
  }, [tableDataFiltered]);

  const columns = [
    {
      accessor: "id",
      id: "location",
      Cell: ({ row }) => {
        let pin = <MdLocationPin size={20} color="#26344D" />;
        if (row.original.id === activeSite) return pin;
        return "";
      },
      disableSortBy: true,
    },
    {
      accessor: "favorite",
      filterType: "SelectCheckbox",
      filterLabel: <MdStarOutline size={20} color="#B3C0D6" />,
      disableSortBy: true,
      Cell: ({ row }) => {
        let star = (
          <MdStarOutline
            size={20}
            color="#B3C0D6"
            style={{ cursor: "pointer" }}
            onClick={() => handleFavoriteSite(row.original, "Favorite")}
          />
        );
        if (row.original.favorite === "Favorite")
          star = (
            <MdStar
              size={20}
              color="#26344D"
              style={{ cursor: "pointer" }}
              onClick={() => handleFavoriteSite(row.original, "Unfavorite")}
            />
          );
        return star;
      },
    },
  ];

  if (includes(sitesFilteredColumnsKeys, "site_status"))
    columns.push({
      id: "site_status",
      accessor: "site_status",
      Cell: ({ row }) => {
        const siteStatus = String(
          row?.original?.site_status ?? ""
        ).toLowerCase();

        switch (siteStatus) {
          case "removed":
            return <BiXCircle size={20} color="#C64E45" />;
          case "selected":
            return <BiCheckCircle size={20} color="#3CC23A" />;
          default:
            return "";
        }
      },
      disableSortBy: true,
    });

  if (includes(sitesFilteredColumnsKeys, "pi_name"))
    columns.push({
      id: "pi_name",
      accessor: "pi_name",
      unit: "Name",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["pi_name"] ? row.original["pi_name"] : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesFilteredColumnsKeys, "site_name"))
    columns.push({
      id: "site_name",
      accessor: "site_name",
      unit: "Name",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original.site_name ? row.original.site_name : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesFilteredColumnsKeys, "clint_network"))
    columns.push({
      id: "clint_network",
      accessor: "clint_network",
      filterType: "SelectCheckbox",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["clint_network"]}
        </CellElement>
      ),
    });

  if (includes(sitesFilteredColumnsKeys, "primary_site_type"))
    columns.push({
      id: "primary_site_type",
      accessor: "primary_site_type",
      filterType: "SelectCheckbox",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["primary_site_type"]
            ? row.original["primary_site_type"]
            : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesFilteredColumnsKeys, "patient_count"))
    columns.push({
      id: "patient_count",
      accessor: "patient_count",
      unit: "N",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["patient_count"]
            ? row.original["patient_count"].toLocaleString("en-US")
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["patient_count"],
    });

  if (includes(sitesFilteredColumnsKeys, "num_patients__eos_gt_250"))
    columns.push({
      id: "num_patients__eos_gt_250",
      accessor: "num_patients__eos_gt_250",
      unit: "N at site",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["num_patients__eos_gt_250"]
            ? row.original["num_patients__eos_gt_250"].toLocaleString("en-US")
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["num_patients__eos_gt_250"],
    });

  if (includes(sitesFilteredColumnsKeys, "num_referral_sites"))
    columns.push({
      id: "num_referral_sites",
      accessor: "num_referral_sites",
      unit: "N",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["num_referral_sites"]
            ? row.original["num_referral_sites"].toLocaleString("en-US")
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["num_referral_sites"],
    });

  if (includes(sitesFilteredColumnsKeys, "num_patients_referral_sites"))
    columns.push({
      id: "num_patients_referral_sites",
      accessor: "num_patients_referral_sites",
      unit: "N",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["num_patients_referral_sites"]
            ? row.original["num_patients_referral_sites"].toLocaleString(
                "en-US"
              )
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["num_patients_referral_sites"],
    });

  if (
    includes(sitesFilteredColumnsKeys, "num_patients_referral_sites_eos_gt_250")
  )
    columns.push({
      id: "num_patients_referral_sites_eos_gt_250",
      accessor: "num_patients_referral_sites_eos_gt_250",
      unit: "N",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["num_patients_referral_sites_eos_gt_250"]
            ? row.original[
                "num_patients_referral_sites_eos_gt_250"
              ].toLocaleString("en-US")
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["num_patients_referral_sites_eos_gt_250"],
    });

  if (includes(sitesFilteredColumnsKeys, "dist_from_source_mi"))
    columns.push({
      id: "dist_from_source_mi",
      accessor: "dist_from_source_mi",
      unit: "Miles",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["dist_from_source_mi"]
            ? row.original["dist_from_source_mi"].toLocaleString("en-US")
            : "-"}
        </CellElement>
      ),
      hiddenColumn: includes(sitesFilteredColumnsKeys, "is_primary_site"),
      tooltip: siteTableInfo.tooltips["dist_from_source_mi"],
    });

  if (includes(sitesFilteredColumnsKeys, "site_key"))
    columns.push({
      id: "site_key",
      accessor: "site_key",
      hiddenColumn: true,
    });

  if (includes(sitesFilteredColumnsKeys, "institution"))
    columns.push({
      id: "institution",
      accessor: "institution",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original.institution ? row.original.institution : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["institution"],
    });

  if (includes(sitesFilteredColumnsKeys, "address"))
    columns.push({
      id: "address",
      accessor: "address",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["address"],
    });

  if (includes(sitesFilteredColumnsKeys, "city"))
    columns.push({
      id: "city",
      accessor: "city",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["city"],
    });

  if (includes(sitesFilteredColumnsKeys, "county"))
    columns.push({
      id: "county",
      accessor: "county",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["county"],
    });

  if (includes(sitesFilteredColumnsKeys, "state"))
    columns.push({
      id: "state",
      accessor: "state",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["state"],
    });

  if (includes(sitesFilteredColumnsKeys, "zipcode"))
    columns.push({
      id: "zipcode",
      accessor: "zipcode",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["zipcode"],
    });

  if (includes(sitesFilteredColumnsKeys, "center_size"))
    columns.push({
      id: "center_size",
      accessor: "center_size",
      filterType: "SelectCheckbox",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["center_size"] ? row.original["center_size"] : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["center_size"],
    });

  if (includes(sitesFilteredColumnsKeys, "institution_type"))
    columns.push({
      id: "institution_type",
      accessor: "institution_type",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original.institution_type ? row.original.institution_type : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["institution_type"],
    });

  if (includes(sitesFilteredColumnsKeys, "eligible_patients"))
    columns.push({
      id: "eligible_patients",
      accessor: "eligible_patients",
      unit: "# by ZIP3",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["eligible_patients"]
            ? row.original["eligible_patients"].toLocaleString("en-US")
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["eligible_patients"],
    });

  if (includes(sitesFilteredColumnsKeys, "zip3_eligibility"))
    columns.push({
      id: "zip3_eligibility",
      accessor: "zip3_eligibility",
      filterType: "SelectSliderRange",
      unit: "% by ZIP3",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["zip3_eligibility"]
            ? row.original["zip3_eligibility"].toFixed(2)
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["zip3_eligibility"],
    });

  if (includes(sitesFilteredColumnsKeys, "zip3_eligible_high_event_risk"))
    columns.push({
      id: "zip3_eligible_high_event_risk",
      accessor: "zip3_eligible_high_event_risk",
      filterType: "SelectSliderRange",
      unit: "% by ZIP3",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["zip3_eligible_high_event_risk"]
            ? row.original["zip3_eligible_high_event_risk"].toFixed(2)
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["zip3_eligible_high_event_risk"],
    });

  if (includes(sitesFilteredColumnsKeys, "zip3_eligible_med_event_risk"))
    columns.push({
      id: "zip3_eligible_med_event_risk",
      accessor: "zip3_eligible_med_event_risk",
      unit: "% by ZIP3",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["zip3_eligible_med_event_risk"],
    });

  if (includes(sitesFilteredColumnsKeys, "zip3_eligible_low_event_risk"))
    columns.push({
      id: "zip3_eligible_low_event_risk",
      accessor: "zip3_eligible_low_event_risk",
      unit: "% by ZIP3",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["zip3_eligible_low_event_risk"],
    });

  if (includes(sitesFilteredColumnsKeys, "pts_with_0_risk_factors"))
    columns.push({
      id: "pts_with_0_risk_factors",
      accessor: "pts_with_0_risk_factors",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["pts_with_0_risk_factors"],
    });

  if (includes(sitesFilteredColumnsKeys, "pts_with_1_risk_factors"))
    columns.push({
      id: "pts_with_1_risk_factors",
      accessor: "pts_with_1_risk_factors",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["pts_with_1_risk_factors"],
    });

  if (includes(sitesFilteredColumnsKeys, "pts_with_2_risk_factors"))
    columns.push({
      id: "pts_with_2_risk_factors",
      accessor: "pts_with_2_risk_factors",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["pts_with_2_risk_factors"],
    });

  if (includes(sitesFilteredColumnsKeys, "pts_with_3_risk_factors"))
    columns.push({
      id: "pts_with_3_risk_factors",
      accessor: "pts_with_3_risk_factors",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["pts_with_3_risk_factors"],
    });

  if (includes(sitesFilteredColumnsKeys, "estimated_enrollment_score"))
    columns.push({
      id: "estimated_enrollment_score",
      accessor: "estimated_enrollment_score",
      unit: "Estimated %",
      filterType: "SelectSliderRange",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["estimated_enrollment_score"]
            ? row.original["estimated_enrollment_score"].toFixed(1)
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["estimated_enrollment_score"],
    });

  if (includes(sitesFilteredColumnsKeys, "estimated_start_up_time"))
    columns.push({
      id: "estimated_start_up_time",
      accessor: "estimated_start_up_time",
      unit: "Estimated Days",
      filterType: "SelectCheckboxRange",
      options: [
        { value: "75-125", label: "75 - 125" },
        { value: "126-176", label: "126 - 176" },
        { value: "177-227", label: "177 - 227" },
        { value: "228-278", label: "228 - 278" },
        { value: "279-329", label: "279 - 329" },
        { value: "330-380", label: "330 - 380" },
        { value: "381-431", label: "381 - 431" },
      ],
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["estimated_start_up_time"]
            ? row.original["estimated_start_up_time"]
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["estimated_start_up_time"],
    });

  if (includes(sitesFilteredColumnsKeys, "irb_type"))
    columns.push({
      id: "irb_type",
      accessor: "irb_type",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["irb_type"] ? row.original["irb_type"] : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["irb_type"],
    });

  if (includes(sitesFilteredColumnsKeys, "has_ct_scanner"))
    columns.push({
      id: "has_ct_scanner",
      accessor: "has_ct_scanner",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["has_ct_scanner"]
            ? row.original["has_ct_scanner"]
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["has_ct_scanner"],
    });

  if (includes(sitesFilteredColumnsKeys, "ascvd_7point5_to_20_percent"))
    columns.push({
      id: "ascvd_7point5_to_20_percent",
      accessor: "ascvd_7point5_to_20_percent",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["ascvd_7point5_to_20_percent"],
    });

  if (includes(sitesFilteredColumnsKeys, "ascvd_over_20_percent"))
    columns.push({
      id: "ascvd_over_20_percent",
      accessor: "ascvd_over_20_percent",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["ascvd_over_20_percent"],
    });

  if (includes(sitesFilteredColumnsKeys, "ldl_70_to_100_mg_per_dl"))
    columns.push({
      id: "ldl_70_to_100_mg_per_dl",
      accessor: "ldl_70_to_100_mg_per_dl",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["ldl_70_to_100_mg_per_dl"],
    });

  if (includes(sitesFilteredColumnsKeys, "ldl_100_to_190_mg_per_dl"))
    columns.push({
      id: "ldl_100_to_190_mg_per_dl",
      accessor: "ldl_100_to_190_mg_per_dl",
      hiddenColumn: true,
      tooltip: siteTableInfo.tooltips["ldl_100_to_190_mg_per_dl"],
    });

  // For the ICMPGN index we want to make two modifications to the table:
  // 1. We want to add a column for the site score, with a filter for the score range
  // 2. We want to sort the table by patients counts, then site score by default
  if (cohortIndex.index_name === "ic_mpgn_linked_claims") {
    columns.push({
      id: "site_score",
      accessor: "site_score",
      filterType: "SelectCheckboxRange",
      options: [
        { value: "0-500", label: "0 - 500" },
        { value: "501-1000", label: "501 - 1000" },
        { value: "1001-1500", label: "1001 - 1500" },
        { value: "1501-2000", label: "1501 - 2000" },
        { value: "2001-2500", label: "2001 - 2500" },
        { value: "2501-3000", label: "2501 - 3000" },
        { value: "3001-3500", label: "3001 - 3500" },
      ],
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["site_score"]
            ? row.original["site_score"].toLocaleString("en-US")
            : "-"}
        </CellElement>
      ),
      tooltip: siteTableInfo.tooltips["ic_mpgn_linked_claimss"],
    });

    columnsDefaultSort = [
      { id: "eligible_patients", desc: true },
      { id: "site_score", desc: true },
    ];
  }

  // get Header properties from SITE_COLUMNS_LABEL object
  for (const column of columns) {
    column.Header = ({ column }) => {
      if (!(column.id in SITE_COLUMNS_LABELS)) {
        return "";
      }

      return SITE_COLUMNS_LABELS[column.id];
    };
  }

  const conditionalRowStyles = [
    {
      when: (row) => row.original.activeSite,
      styles: {
        fontWeight: "700",
        color: "var(--primary)",
      },
    },
  ];

  function getPanelActions() {
    const actions = [];
    if (isEmpty(sites)) return actions;

    actions.push(
      <SearchInput
        marginBottom={0}
        onChange={(value) => setSiteSearchValue(value)}
        resetSearch={() => setSiteSearchValue("")}
        value={siteSearchValue}
      />
    );

    actions.push(
      <KebabMenu key="kebabMenu" width="200px">
        <DownloadCsvMenuItem
          key="downloadAllSites"
          label="Download all sites"
          filename="all-sites.csv"
          csvString={getSitesCsvString(
            subcohortList[selectedCohort].name,
            columns,
            tableData
          )}
        />
        <DownloadCsvMenuItem
          key="downloadFavoriteSites"
          icon={<MdStar size={12} color="26344D" />}
          label="Download favorite sites"
          filename="favorite-sites.csv"
          csvString={getSitesCsvString(
            subcohortList[selectedCohort].name,
            columns,
            tableData,
            favoriteSitesIdList
          )}
        />
      </KebabMenu>
    );

    return actions;
  }
  const showInfoCircle = siteTableInfo !== null;
  const { toggleModal, isShowingModal } = useModal();

  function toggleInfoBoxModal() {
    const newIsShowingModal = !isShowingModal;
    toggleModal();

    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`Site Selection - Toggle Info Box Modal`, {
      isShowingModal: newIsShowingModal,
      urlPathname: location.pathname,
    });
  }

  const infoBoxModal = showInfoCircle
    ? getInfoBoxModal(siteTableInfo, toggleInfoBoxModal, isShowingModal)
    : null;

  const getPanelTitle = () => {
    if (showInfoCircle)
      return (
        <PanelTitleContainer>
          Site Selection
          <BsInfoCircle
            size={16}
            color="4d5f80"
            style={{ cursor: "pointer" }}
            onClick={() => {
              toggleInfoBoxModal();
            }}
          />
        </PanelTitleContainer>
      );
    return "Site Selection";
  };

  const onFilterTableDataChange = (newFilteredSites) => {
    if (
      newFilteredSites.length > 0 &&
      !includes(
        newFilteredSites.map((f) => f.id),
        activeSite
      )
    )
      setActiveSite(newFilteredSites[0].id);
    setFilteredSites([...newFilteredSites]);
  };

  return (
    <Panel>
      <SitesTable>
        <Header>
          <Heading3 marginBottom={0}>{getPanelTitle()}</Heading3>
          <ActionsContainer>{getPanelActions()}</ActionsContainer>
        </Header>

        {isEmpty(sites) ? (
          <Loader />
        ) : (
          <>
            {sitesOptions.length > 0 ? (
              <DrillDownSelect>
                <Small color="#666666">Referring physicians near</Small>
                <Select
                  borderless={true}
                  marginBottom={0}
                  options={sitesOptions}
                  value={drilldownSite}
                  placeholder="Choose a Physician or a reference site"
                  onChange={(value) => setDrilldownSite(value)}
                  style={{ width: "350px" }}
                />
                {drilldownSite !== "" ? (
                  <BackLink onClick={() => setDrilldownSite("")}>
                    Back to all sites view
                  </BackLink>
                ) : (
                  ""
                )}
              </DrillDownSelect>
            ) : (
              ""
            )}
            <FilteredTable
              columns={columns}
              data={tableDataFiltered}
              globalFilterValue={siteSearchValue}
              conditionalRowStyles={conditionalRowStyles}
              defaultSort={columnsDefaultSort}
              onFilterDataChange={onFilterTableDataChange}
              tableHeight={500}
              stickyHeader
            />
          </>
        )}
      </SitesTable>
      <SitesGeomapDetails
        sites={filteredSites}
        activeSite={activeSite}
        setActiveSite={setActiveSite}
        selectedCohort={selectedCohort}
        setValidCharts={setValidCharts}
        setErrorMsg={setErrorMsg}
      />

      {showInfoCircle && infoBoxModal}
    </Panel>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getInfoBoxModal(
  { title, compositeEndpoints, details },
  toggleModal,
  isShowingModal
) {
  return (
    <InfoModal
      key="infoModal"
      title={title}
      toggleModal={toggleModal}
      isShowingModal={isShowingModal}
    >
      {details ? (
        <DetailsContainer>
          {Array.from(details.entries()).map(([key, value]) => {
            return (
              <div key={key}>
                <Heading4>
                  {key
                    .split("_")
                    .map((s) => capitalizeFirstLetter(s))
                    .join(" ")}
                  :{" "}
                </Heading4>
                {value}
              </div>
            );
          })}
        </DetailsContainer>
      ) : (
        ""
      )}
      <Small>
        The SELECT tab displays both the officially selected and potential sites
        for a given trial.
      </Small>
      {compositeEndpoints && Object.keys(compositeEndpoints).length !== 0 && (
        <CollapsiblesContainer>
          {Object.entries(compositeEndpoints).map(([title, description], i) => {
            const titleComponent =
              title === "Check mark" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Heading4>Selected Status</Heading4>
                  <BiCheckCircle
                    size={20}
                    color="#3CC23A"
                    style={{ marginLeft: 5 }}
                  />
                </div>
              ) : title === "Removed mark" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Heading4>Removed Status</Heading4>
                  <BiXCircle
                    size={20}
                    color="#C64E45"
                    style={{ marginLeft: 5 }}
                  />
                </div>
              ) : (
                <Heading4>{title}</Heading4>
              );
            return (
              <Collapsible
                key={title}
                title={titleComponent}
                border="1px solid #e6e6e6"
                borderTop={i === 0 ? null : 0}
                padding="10px"
              >
                {description}
              </Collapsible>
            );
          })}
        </CollapsiblesContainer>
      )}
    </InfoModal>
  );
}
