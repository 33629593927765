import styled, { css } from "styled-components";

export const CombinatorButton = styled.button`
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  border-radius: 3px;
  border: 1px solid #27a1c9;
  background-color: #27a1c9;
  cursor: pointer;
  position: relative;

  ${(props) => {
    switch (props.combinatorType) {
      case "rule":
        return css`
          padding: 0 7px;
          margin-bottom: 10px;
          align-self: flex-start;
        `;
      case "criteria":
        return css`
          padding: 5px 7px;
          align-self: center;
          z-index: 1;
          background-color: #26344d;
          border: 1px solid #26344d;
          margin-bottom: 10px;

          &::before,
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            width: 1px;
            height: 10px;
            background-color: #26344d;
          }
          &::before {
            top: -11px;
          }

          &::after {
            bottom: -11px;
          }
        `;
      case "group":
        return css`
          margin-bottom: -7px;
          padding: 5px 7px;
          align-self: center;
          z-index: 1;
        `;
    }
  }}
`;

export const RuleGroupContainer = styled.div`
  border-radius: 3px;
  background-color: #fff;
  ${(props) => {
    switch (props.groupDepth) {
      case 0:
        return css``;
      case 1:
        return css`
          border: 1px solid #26344d;
        `;
      default:
        return css`
          border: 1px dashed #c9d3d8;
        `;
    }
  }}
`;

export const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const RuleGroupContent = styled.div`
  padding: 8px;
`;

export const EmptyStateContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 100px;

  ${(props) => {
    switch (props.groupDepth) {
      case 0:
        return css`
          margin-bottom: 10px;
          cursor: pointer;
          height: auto;

          > button {
            flex: 1;
          }
        `;
    }
  }}
`;

export const RuleGroupHeader = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  ${(props) => {
    switch (props.groupDepth) {
      case 0:
        return css`
          padding: 0px 8px;
        `;
      case 1:
        return css`
          border-bottom: ${(props) => (props.borderless ? 0 : "1px solid")};
          background-color: #26344d;
          border-color: #26344d;
          font-size: 1rem;
          font-weight: 700;
          color: #fff;
          padding: 8px;
          text-transform: uppercase;
        `;
      default:
        return css`
          border-bottom: ${(props) => (props.borderless ? 0 : "1px solid")};
          background-color: #edf0f2;
          border-color: #c9d3d8;
          color: #2f3237;
          padding: 8px;
        `;
    }
  }}
`;

export const RuleGroupFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  gap: 8px;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1;
  ${(props) => {
    switch (props.groupDepth) {
      case 0:
        return css`
          justify-content: space-between;
        `;
      default:
        return css`
          justify-content: flex-end;
        `;
    }
  }}
`;

export const Button = styled.button`
  background-color: transparent;

  color: #20769a;
  font-weight: 400;
  font-size: 14px;

  cursor: pointer;

  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;

  border-radius: 3px;

  &:hover {
    color: #214358;
    border-color: #214358;
  }

  ${(props) => {
    switch (props.groupDepth) {
      case 0:
        return css`
          color: #fff;
          padding: 8px;
          border: 1px solid #20769a;
          background-color: #20769a;
          width: 100%;

          &:hover {
            background-color: #214358;
            border-color: #214358;
            color: #fff;
          }
        `;
      default:
        return css`
          padding: 0;
          border: none;
        `;
    }
  }}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: #727b8b;
      border-color: #edf0f2;

      &:hover {
        background-color: transparent;
      }
    `}
`;
export const CollapseButton = styled.button`
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: -9px -9px -9px 0;
  padding: 9px;
  border: 0;

  ${(props) => {
    switch (props.groupDepth) {
      case 1:
        return css`
          color: #fff;
          border-left: 1px solid #fff;
        `;
      default:
        return css`
          color: #2f3237;
          border-left: 1px solid #c9d3d8;
        `;
    }
  }}
`;
export const RuleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
`;
