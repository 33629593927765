import React, { useEffect, useState } from "react";
import { rgba } from "polished";
import { isEmpty } from "lodash";

import CumulativeEventRateWrapper from "../cumulative-event-rate-wrapper";
import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";
import {
  getEventRateCurve,
  getTable2Data,
} from "../../../../services/insights-data-service";

function AwareEventRateCurve({ setErrorMsg, setValidCharts }) {
  const { subcohortList, dispatch: subcohortListDispatch } =
    useIdentifySubcohortList();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex, filtersConfig, ercConfig, table2Config, settings } =
    cohortConfigs;
  const [chartsResponseList, setChartsResponseList] = useState({});
  const [tablesResponseList, setTablesResponseList] = useState({});
  const [charts, setCharts] = useState([]);
  const [table, setTable] = useState({});

  const [infoBox, setInfoBox] = useState({});

  function getInfoBox(ercConfig) {
    const compositeEndpoints = {};
    for (const config of ercConfig ?? []) {
      if (!("description" in config)) {
        continue;
      }
      compositeEndpoints[config.display_name] = config.description;
    }

    if (!isEmpty(compositeEndpoints))
      return {
        title: "Cumulative Event Rate Curve",
        compositeEndpoints,
      };

    return {};
  }

  useEffect(() => {
    setInfoBox(getInfoBox(ercConfig));
  }, [ercConfig]);

  async function getChartData(cohortQuery) {
    const response = await getEventRateCurve(
      cohortIndex.index_name,
      cohortQuery,
      ercConfig
    );
    if (response.data.valid) {
      return {
        cohortSize: response.data.data.cohort_size,
        result: response.data.data.result,
        descriptions: response.data.data.descriptions,
      };
    }
    setValidCharts(false);
    setErrorMsg(response.data.messages.join());
    return {};
  }

  async function getTableData(cohortQuery) {
    const response = await getTable2Data(
      cohortIndex.index_name,
      cohortQuery,
      ercConfig,
      table2Config
    );

    if (response.data.valid) {
      return response.data.data.result;
    }
    setValidCharts(false);
    setErrorMsg(response.data.messages.join());
    return {};
  }

  function getChartConfig(chart, data) {
    const config = {};
    const colorPalette = [];
    Object.keys(subcohortList).forEach((id) => {
      if (id in subcohortList && subcohortList[id].visible)
        colorPalette.push(subcohortList[id].color);
    });
    config.name = chart;
    config.data = data;
    config.config = {
      type: "line-ci",
      layout: "linear",
      rotateXAxisLabels: false,
      alphanumSort: true,
      axesXType: "continuous",
      normalizeToogle: false,
      hideLegends: true,
      showTooltip: true,
      colorPalette: colorPalette,
    };
    config.meta = {
      axesTitle: {
        x: "Days since index date",
        y: "Cumulative Event Rate (%)",
      },
      chartTitle: "Cumulative Event Rate",
    };
    return config;
  }

  function parseTablesResponse() {
    if (!tablesResponseList.cohort) return {};
    const outcomes = Object.keys(tablesResponseList.cohort);
    if (!outcomes || !outcomes.length) {
      return {};
    }

    outcomes.sort();

    const years = tablesResponseList.cohort[outcomes[0]].data[0].values.map(
      (d) => d.key
    );

    function getYearsHeaders(year) {
      const numYear = Math.trunc(year / 365);
      const yearHeader = {
        Header: `Year ${numYear}`,
        accessor: numYear.toString(),
      };
      return yearHeader;
    }

    function getColumns(years) {
      const columns = [
        {
          Header: "Outcomes",
          accessor: "outcome",
          enableRowSpan: true,
        },
        {
          Header: "Cohort",
          accessor: "cohort",
          hiddenColumn: true,
        },
      ];

      years.forEach((year) => {
        columns.push(getYearsHeaders(year));
      });

      return columns;
    }

    function getRows(outcomes) {
      const newRows = [];

      Object.keys(tablesResponseList).forEach((id) => {
        if (id in subcohortList && subcohortList[id].visible) {
          outcomes.forEach((outcome) => {
            const newRow = {};
            newRow.cohort = id;
            newRow.outcome = outcome;
            tablesResponseList[id][outcome].data[0].values.forEach((d) => {
              newRow[Math.trunc(d.key / 365).toString()] = (
                d.value * 100
              ).toFixed(2);
            });
            newRows.push(newRow);
          });
        }
      });

      return newRows;
    }

    function getConditionalRowStyles() {
      const styles = [];
      Object.keys(tablesResponseList).forEach((id) => {
        if (id in subcohortList && subcohortList[id].visible) {
          styles.push({
            when: (row) => row.original.cohort === id,
            styles: {
              backgroundColor: rgba(subcohortList[id].color, 0.1),
            },
          });
        }
      });
      return styles;
    }
    function getConditionalCellStyles() {
      const styles = [];
      styles.push({
        when: (cell) => cell.column.id === "outcome",
        styles: {
          backgroundColor: rgba("#fff", 0.1),
        },
      });
      return styles;
    }

    const columns = getColumns(years);
    const rows = getRows(outcomes);
    const conditionalRowStyles = getConditionalRowStyles();
    const conditionalCellStyles = getConditionalCellStyles();

    return { columns, rows, conditionalRowStyles, conditionalCellStyles };
  }

  function parseChartsResponse() {
    const chartsOptions = Object.keys(
      chartsResponseList.cohort?.result || {}
    ).sort();

    const dataList = {};
    chartsOptions.forEach((chart) => {
      const data = [];
      Object.keys(chartsResponseList).forEach((subcohortID) => {
        if (
          subcohortID in subcohortList &&
          subcohortList[subcohortID].visible &&
          chartsResponseList[subcohortID]?.result &&
          chart in chartsResponseList[subcohortID].result
        ) {
          const lineData =
            chartsResponseList[subcohortID].result[chart].data[0];
          const newData = {
            name: subcohortList[subcohortID].name,
            values: lineData.values.map((d) => ({
              ...d,
              value: d.value * 100,
            })),
            confidence_intervals: lineData.confidence_intervals.map((d) => ({
              ...d,
              lower_val: d.lower_val * 100,
              upper_val: d.upper_val * 100,
            })),
          };
          data.push(newData);
        }
      });
      dataList[chart] = data;
    });

    return dataList;
  }

  useEffect(async () => {
    const newChartsResponseList = { ...chartsResponseList };
    let fetchedID;

    // Trigger the get chart data for the subcohort that was changed or added

    for (const id in subcohortList) {
      if (
        !subcohortList[id].fetchedData.erc &&
        subcohortList[id].status !== "isCreating"
      ) {
        setCharts([]);
        if (ercConfig) {
          const response = await getChartData(subcohortList[id].query);
          newChartsResponseList[id] = response;
          fetchedID = id;
          //Update the subcohort population size
          if (response.cohortSize >= 0)
            subcohortListDispatch({
              type: "UPDATE_SUBCOHORT_SIZE",
              payload: {
                size: response.cohortSize,
                id: id,
              },
            });
        }
      } else if (subcohortList[id].fetchedData.erc) {
        newChartsResponseList[id] = subcohortList[id].fetchedData.erc;
      }
    }

    //Treat the remove subcohorts, if removed from subcohortList will be removed from response list
    for (const id in newChartsResponseList) {
      if (!(id in subcohortList)) delete newChartsResponseList[id];
    }

    setChartsResponseList(newChartsResponseList);

    //Change the subcohort that were edited or added to loaded
    if (fetchedID)
      subcohortListDispatch({
        type: "UPDATE_SUBCOHORT_FETCHED_DATA",
        payload: {
          chart: "erc",
          fetchedData: newChartsResponseList[fetchedID],
          id: fetchedID,
        },
      });
  }, [
    subcohortList.cohort?.status,
    subcohortList["subcohort-a"]?.status,
    subcohortList["subcohort-b"]?.status,
    subcohortList["subcohort-c"]?.status,
    ercConfig,
  ]);

  // Trigger build of the charts config structure
  useEffect(() => {
    const dataList = parseChartsResponse();
    const newCharts = [];
    for (const key of Object.keys(dataList)) {
      newCharts.push(getChartConfig(key, dataList[key]));
    }
    setCharts(newCharts);
  }, [
    chartsResponseList.cohort,
    chartsResponseList["subcohort-a"],
    chartsResponseList["subcohort-b"],
    chartsResponseList["subcohort-c"],
    subcohortList?.cohort?.visible,
  ]);

  useEffect(async () => {
    const newTablesResponseList = { ...tablesResponseList };
    let fetchedID;

    for (const id in subcohortList) {
      if (
        !subcohortList[id].fetchedData.tableTwo &&
        subcohortList[id].status !== "isCreating"
      ) {
        setTable({}); // to assure that a loading will appear while getting the data
        if (ercConfig && table2Config) {
          const response = await getTableData(subcohortList[id].query);
          newTablesResponseList[id] = response;
          fetchedID = id;
        }
      } else if (subcohortList[id].fetchedData.tableTwo) {
        newTablesResponseList[id] = subcohortList[id].fetchedData.tableTwo;
      }
    }

    //Treat the remove subcohorts, if removed from subcohortList will be removed from response list
    for (const id in newTablesResponseList) {
      if (!(id in subcohortList)) delete newTablesResponseList[id];
    }

    setTablesResponseList(newTablesResponseList);

    //Change the subcohort that were edited or added to loaded
    if (fetchedID)
      subcohortListDispatch({
        type: "UPDATE_SUBCOHORT_FETCHED_DATA",
        payload: {
          chart: "tableTwo",
          fetchedData: newTablesResponseList[fetchedID],
          id: fetchedID,
        },
      });
  }, [
    subcohortList.cohort?.status,
    subcohortList["subcohort-a"]?.status,
    subcohortList["subcohort-b"]?.status,
    subcohortList["subcohort-c"]?.status,
    ercConfig,
    table2Config,
  ]);

  // Trigger build of the table config structure
  useEffect(() => {
    const newTable = parseTablesResponse();
    setTable(newTable);
  }, [
    tablesResponseList.cohort,
    tablesResponseList["subcohort-a"],
    tablesResponseList["subcohort-b"],
    tablesResponseList["subcohort-c"],
    subcohortList?.cohort?.visible,
  ]);

  const showPanelTabs = table2Config !== null && charts.length > 0;

  return (
    <CumulativeEventRateWrapper
      infoBox={infoBox}
      charts={charts}
      table={table}
      showPanelTabs={showPanelTabs}
      exportEnabled={settings.erc_export_enabled}
    />
  );
}

export default AwareEventRateCurve;
