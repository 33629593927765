export const hasCompleteRule = (query) => {
  if (!query || typeof query !== "object" || !query.rules) return false;

  const checkRules = (rules) => {
    return rules.some((rule) => {
      if (rule.combinator) {
        // Recursively check the nested group for complete rules
        return checkRules(rule.rules);
      } else {
        // Check if this rule has a complete set of field, operator, and value
        return (
          typeof rule.field === "string" &&
          rule.field.trim() !== "" &&
          typeof rule.operator === "string" &&
          rule.operator.trim() !== "" &&
          typeof rule.value === "string" &&
          rule.value.trim() !== ""
        );
      }
    });
  };

  // Start by checking the top-level rules
  return checkRules(query.rules);
};

// Function to check if a rule is empty (field, operator, and value are all empty)
const isEmptyRule = (rule) => {
  return (
    (!rule.field || rule.field.trim() === "") &&
    (!rule.operator || rule.operator.trim() === "") &&
    (!rule.value || rule.value.trim() === "")
  );
};

// Recursive function to clean query
export const cleanQuery = (query) => {
  const cleanRules = (rules) => {
    return rules
      .filter((rule) => {
        if (rule.combinator) {
          // If it's a group, recursively clean it
          const cleanedGroup = cleanRules(rule.rules);

          // Return the group only if it has non-empty rules
          return cleanedGroup.length > 0;
        } else {
          // Return the rule only if it's not empty
          return !isEmptyRule(rule);
        }
      })
      .map((rule) => {
        if (rule.combinator) {
          // Recursively clean nested groups
          return {
            ...rule,
            rules: cleanRules(rule.rules),
          };
        } else {
          return rule;
        }
      });
  };

  if (!query || !query.rules || query.rules.length === 0) return null;

  // Clean the top-level rules
  const cleanedRules = cleanRules(query.rules);

  // Return the cleaned query only if there are valid rules
  return cleanedRules.length > 0 ? { ...query, rules: cleanedRules } : null;
};

export const validateQuery = (query, fields) => {
  let errors = {};

  const validateRules = (rules) => {
    return rules.reduce((acc, rule) => {
      if (rule.combinator) {
        // Validate nested groups
        const groupErrors = validateRules(rule.rules);
        if (Object.keys(groupErrors).length > 0) {
          acc[rule.id] = groupErrors;
        }
      } else {
        // Validate individual rules
        let ruleErrors = {};
        if (rule.field && rule.field.trim() !== "") {
          if (!rule.operator || rule.operator.trim() === "") {
            ruleErrors.operator = "Operator is required";
          }
          const selectedField = fields.find((f) => f.value === rule.field);
          if (selectedField && selectedField.type === "numerical") {
            const value = parseFloat(rule.value);
            let [min, max] = selectedField.options;
            if (isNaN(value)) {
              ruleErrors.value = `Value must be between ${min} and ${max}`;
            } else {
              if (min !== undefined && value < min) {
                ruleErrors.value = `Value must be greater than or equal to ${min}`;
              }
              if (max !== undefined && value > max) {
                ruleErrors.value = `Value must be less than or equal to ${max}`;
              }
            }
          } else if (!rule.value || rule.value.trim() === "") {
            ruleErrors.value = "Value is required";
          }
        }

        if (Object.keys(ruleErrors).length > 0) {
          acc[rule.id] = ruleErrors;
        }
      }

      return acc;
    }, {});
  };

  const queryErrors = validateRules(query.rules);
  if (Object.keys(queryErrors).length > 0) {
    errors = queryErrors;
  }

  return errors;
};

export const getFieldValuesFromQuery = (query) => {
  const fieldValues = {};

  const traverseRules = (rules) => {
    rules.forEach((rule) => {
      if (rule.combinator) {
        // If it's a group, recursively check its rules
        traverseRules(rule.rules);
      } else {
        // For individual rules, gather the field and value
        const { field, value } = rule;
        if (field && value !== null) {
          if (!fieldValues[field]) {
            fieldValues[field] = [];
          }
          // Add the value to the array if it's not already present
          if (!fieldValues[field].includes(value)) {
            fieldValues[field].push(value);
          }
        }
      }
    });
  };

  if (query && query.rules) {
    traverseRules(query.rules);
  }

  return fieldValues;
};

let operatorNote = {
  is: "equal to",
  isnot: "not equal to",
  eq: "equal to",
  neq: "not equal to",
  lt: "less than",
  lte: "less than or equal to",
  gt: "greater than",
  gte: "greater than or equal to",
};

export const formatQueryString = (query, fields) => {
  const formatRule = (rule) => {
    let ruleSentence = "has";
    const attribute = fields.find((f) => f.value === rule.field);
    if (!attribute) return "";
    if (attribute.type == "boolean") {
      if (rule.value === "false") ruleSentence = `${ruleSentence} ` + `no`;
      ruleSentence = `${ruleSentence} ${attribute.label}`;
    } else if (
      attribute.type == "numerical" ||
      attribute.type == "categorical"
    ) {
      ruleSentence = `${ruleSentence} ${attribute.label} ${
        operatorNote[rule.operator]
      } ${rule.value}`;
    }
    return ruleSentence;
  };

  const traverseRules = (rules, combinator) => {
    return rules
      .map((rule) => {
        if (rule.combinator) {
          // Recursively format the nested group with parentheses
          const groupString = traverseRules(rule.rules, rule.combinator);
          return `(${groupString})`;
        } else {
          // Format the individual rule
          return formatRule(rule);
        }
      })
      .join(` ${combinator.toUpperCase()} `);
  };

  if (!query || !query.rules || query.rules.length === 0) return "";

  // Start by formatting the top-level rules with the top-level combinator
  return traverseRules(query.rules, query.combinator);
};

const generateId = (groupIndex, ruleIndex) =>
  `${groupIndex.join("-")}-${ruleIndex}`;

export const addRuleToGroup = (group, groupIndex, id) => {
  const newRule = {
    id: generateId(id, group.rules.length),
    type: "rule",
    field: "",
    operator: "",
    value: "",
  };
  return { ...group, rules: [...group.rules, newRule] };
};

export const addGroupToGroup = (group, groupIndex, id) => {
  const newGroup = {
    id: generateId(id, group.rules.length),
    type: "group",
    combinator: "and",
    rules: [],
  };
  return { ...group, rules: [...group.rules, newGroup] };
};

export const removeRuleFromGroup = (group, groupIndex, ruleIndex) => {
  return {
    ...group,
    rules: group.rules.filter((_, index) => index !== ruleIndex),
  };
};

export const removeGroupFromGroup = (group, groupIndex) => {
  if (groupIndex.length === 0) return group;
  const [currentIndex, ...rest] = groupIndex;
  if (rest.length === 0) {
    return {
      ...group,
      rules: group.rules.filter((_, index) => index !== currentIndex),
    };
  }
  return {
    ...group,
    rules: group.rules.map((rule, index) =>
      index === currentIndex ? removeGroupFromGroup(rule, rest) : rule
    ),
  };
};

export const updateGroup = (group, groupIndex, updateFunc, ...args) => {
  if (groupIndex.length === 0) return updateFunc(group, groupIndex, ...args);
  const [currentIndex, ...rest] = groupIndex;
  return {
    ...group,
    rules: group.rules.map((rule, index) =>
      index === currentIndex
        ? updateGroup(rule, rest, updateFunc, ...args)
        : rule
    ),
  };
};
