/*
 * Re-usable methods for Dashboard
 */
import { cloneDeep, isEmpty } from "lodash";
import React from "react";

const DashboardHelper = class {
  static roundUpNumber(x, sig = 0) {
    /*
    Transforms the number into a suffixed version for thousands and millions.
    The optional parameter `sig` specifies the number of significant digits.

    Examples:
    roundUpNumber(1000) -> 1K
    roundUpNumber(3468000) -> 3M
    roundUpNumber(3468000, 2) -> 3.47M
    */
    let num;
    let suffix;
    x = 1 * x;
    if (x >= 1000000) {
      num = x / 1000000;
      suffix = "M";
    } else if (x >= 1000) {
      num = x / 1000;
      suffix = "K";
    } else {
      num = x;
      suffix = "";
    }
    if (num.toFixed(sig) - Math.round(num) == 0.0) {
      return num.toFixed(0) + suffix;
    }
    return num.toFixed(sig) + suffix;
  }

  static printAttributeUnit(attribute) {
    /*
    Returns the Unit of the attribute.
    Likely to be handled already in the back-end in the future.
    */
    switch (attribute) {
      case "age":
        return "years";
        break;
      case "height":
        return "inches";
        break;
      case "diastolic_bp":
        return "mmHg";
        break;
      case "systolic_bp":
        return "mmHg";
        break;
      case "a1c":
        return "%";
        break;
      case "glomerular_filtration_rate":
        return "ml/min";
        break;
      case "bmi":
        return "kg/m²";
        break;
      case "total_cholesterol":
        return "mg/dL";
        break;
      case "ldl_cholesterol":
        return "mg/dL";
        break;
      case "hdl_cholesterol":
        return "mg/dL";
        break;
      case "weight":
        return "pounds";
        break;
      case "triglyceride":
        return "mg/dL";
        break;
      case "calculated_gfr":
        return "mL/min/1.73m²";
        break;
      case "days_wash_in":
        return "days";
        break;
      case "days_follow_up":
        return "days";
        break;
    }
  }

  static printAttributeHumanReadable(attribute) {
    // return only endpoint name
    const match = attribute.match(/(?<endpoint>Endpoint \d+).+/);
    if (match?.groups?.endpoint) {
      return match.groups.endpoint;
    }

    /*
    Returns a human-readable version of a string.
    Likely to be handled already in the back-end in the future.
    */
    switch (attribute) {
      case "ascvd":
        return "ASCVD";
        break;
      case "qrisk":
        return "QRISK";
        break;
      case "bmi":
        return "BMI";
        break;
      case "ldl_cholesterol":
        return "LDL Cholesterol";
        break;
      case "hdl_cholesterol":
        return "HDL Cholesterol";
        break;
      case "diastolic_bp":
        return "BP Diastolic";
        break;
      case "systolic_bp":
        return "BP Systolic";
        break;
      case "a1c":
        return "A1C";
        break;
      case "inr":
        return "International Normalized Ratio";
        break;
      case "lvef":
        return "Left Ventricular Ejection Fraction";
        break;
      case "cha2ds2vasc_score":
        return "CHADS2VASc Score";
        break;
      case "thiazide_diuretics":
        return "Thiazide Diuretics";
        break;
      case "beta_blocking_agents":
        return "Beta Blockers";
        break;
      case "angiotensin_ii_receptor_blocker":
        return "ARBs";
        break;
      case "angiotensin-converting_enzyme_inhibitor_agents":
        return "ACE Inhibitors";
        break;
      case "hmg-coa_reductase_inhibitors":
        return "Statins";
        break;
      case "sodium_glucose_cotransporter_subtype_2_inhibitors":
        return "SGLT2 Inhibitors";
        break;
      case "glucagon-like_peptide-1_receptor_agonist":
        return "GLP-1 Agonists";
        break;
      case "antihypertensives__systolic_bp":
        return "Antihypertensives/Systolic BP";
        break;
      case "anticoagulants__cha2ds2vasc_score":
        return "Anticoagulants/CHADS2VASc Score";
        break;
      case "hmg-coa_reductase_inhibitors__ldl_cholesterol":
        return "Statins/LDL Cholesterol";
        break;
      case "blood_glucose_regulation_agents__a1c":
        return "Blood Glucose Regulation Agents/A1C";
        break;
      case "class_iii_antiarrhythmics":
        return "Class III Antiarrhythmics";
        break;
      case "usa_state":
        return "USA State";
        break;
      case "usa_zip3":
        return "USA Zip3";
        break;
      case "genet_hcm_dcm":
        return "HCM/DCM-Specific Genetic Test";
        break;
      case "heart_failure_diastolic_hf_preserved_ef":
        return "Heart Failure Diastolic HF Preserved EF";
        break;
      case "heart_failure_systolic_hf_reduced_ef":
        return "Heart Failure Systolic HF Reduced EF";
        break;
      case "calculated_gfr":
        return "GFR";
        break;
      case "days_wash_in":
        return "Wash-in time";
        break;
      case "days_follow_up":
        return "Follow-up time";
        break;
      case "direct_oacs":
        return "Direct OACs";
        break;
      case "genetic_test":
        return "General Genetic Test";
        break;
      case "hypercholesterolemia_familial":
        return "Familial Hypercholesterolemia";
        break;
      case "stroke_ischemic":
        return "Ischemic Stroke";
        break;
      case "stroke_hemorrhagic":
        return "Hemorrhagic Stroke";
        break;
      case "stroke_transient_ischemic_attack":
        return "Transient Ischemic Attack";
        break;
      case "obesity_both_sources":
        return "Obesity";
        break;
      case "renal_diseases":
        return "Renal Disease";
        break;
      case "syncope_collapse":
        return "Syncope/Collapse";
        break;
      case "edema_peripheral":
        return "Peripheral Edema";
        break;
      case "No edema_peripheral":
        return "No Peripheral Edema";
        break;
      case "pregnancy_current":
        return "Current Pregnancy";
        break;
      case "hemochromatosis_hereditary":
        return "Hereditary Hemochromatosis";
        break;
      case "gender_female":
        return "Female";
        break;
      case "gender_male":
        return "Male";
        break;
      case "gender_unknown":
        return "Unknown";
        break;
      case "primary_care_visits_all_sum":
        return "Primary care visits per year";
        break;
      case "cardiology_visits_all_sum":
        return "Cardiology visits per year";
        break;
      case "genetics_visits_all_sum":
        return "Genetics visits per year";
        break;
      case "genetic_test_result_hcm_dcm_183d_before_or_183d_after":
        return "Genetic testing - HCM/DCM";
        break;
      case "genetic_test_cardiovascular_183d_before_or_183d_after":
        return "Genetic testing - cardiovascular";
        break;
      case "genetic_test_183d_before_or_183d_after":
        return "Genetic testing - general";
        break;
      case "genetic_counseling_183d_before_or_183d_after":
        return "Genetic counseling";
        break;
      case "genetic_referral_183d_before_or_183d_after":
        return "Referral to geneticist";
        break;
      case "genetic_screening_183d_before_or_183d_after":
        return "Genetic screening";
        break;
      case "genetic_test_screen_counseling_referral_183d_before_or_183d_after":
        return "Genetic test,  screening,  counseling,  or referral";
        break;
      case "cardiomyopathy_alcoholic":
        return "Alcoholic Cardiomyopathy";
        break;
      case "cardiomyopathy_drug":
        return "Cardiomyopathy due to drug/external agent";
        break;
      case "hypertrophic_cardiomyopathy_obstructive":
        return "Hypertrophic cardiomyopathy - obstructive";
        break;
      case "hypertrophic_cardiomyopathy_nonobstructive":
        return "Hypertrophic cardiomyopathy - nonobstructive";
        break;
      case "hypertrophic_cardiomyopathy_unspecified":
        return "Hypertrophic cardiomyopathy - unspecified";
        break;
      case "hypertrophic_cardiomyopathy_familial":
        return "Hypertrophic cardiomyopathy - familial";
        break;
      default:
        return attribute
          .replace(/_/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase());
    }
  }

  static getSubcohortDefaultName(query, attributeList) {
    let name = "";
    Object.keys(query).forEach((queryFilter, index) => {
      if (index !== 0) name += " | ";
      name += `${attributeList[queryFilter].label}: [${query[
        queryFilter
      ].join()}]`;
    });
    return name;
  }

  static buildQueryFromFilters(filters, attributeList) {
    const newQueryStatments = [];

    Object.keys(filters).forEach((attributeKey) => {
      if (filters[attributeKey].length > 0) {
        let queryGroup = {};
        queryGroup.id = attributeKey;
        const { type } = attributeList[attributeKey];
        switch (type) {
          case "numerical":
            queryGroup = cloneDeep(defaultAndGroup);
            queryGroup.rules.push({
              type: "rule",
              field: attributeKey,
              operator: "gte",
              value: filters[attributeKey][0].toString(),
            });
            queryGroup.rules.push({
              type: "rule",
              field: attributeKey,
              operator: "lte",
              value: filters[attributeKey][1].toString(),
            });
            break;
          case "boolean":
            queryGroup = cloneDeep(defaultOrGroup);
            filters[attributeKey].map((value) => {
              queryGroup.rules.push({
                type: "rule",
                field: attributeKey,
                operator: "is",
                value,
              });
            });
            break;
          case "categorical":
            queryGroup = cloneDeep(defaultOrGroup);
            filters[attributeKey].map((value) => {
              queryGroup.rules.push({
                type: "rule",
                field: attributeKey,
                operator: "is",
                value,
              });
            });
            break;
          default:
            break;
        }
        if (!isEmpty(queryGroup)) newQueryStatments.push(queryGroup);
      }
    });

    return {
      ...defaultQuery,
      rules: newQueryStatments,
    };
  }

  static stringfyFilterQuery(query) {
    let stringQuery = "";
    if (query) {
      if (query.type == "group") {
        stringQuery = query.combinator == "and" ? "ALL ( " : "ANY ( ";
        query.rules.forEach((statement) => {
          stringQuery = `${stringQuery} ${this.stringfyFilterQuery(statement)}`;
        });
        stringQuery = `${stringQuery} )`;
      } else {
        stringQuery = `${query.field} ${query.operator} ${query.value}`;
      }
    }
    return stringQuery;
  }

  static showEllipsisLabel(label, limit) {
    if (label.length >= limit) return label.slice(0, limit) + "...";
    return label;
  }
};

export default DashboardHelper;

const defaultQuery = {
  type: "group",
  combinator: "and",
  rules: [],
};

const defaultAndGroup = {
  type: "group",
  combinator: "and",
  rules: [],
};

const defaultOrGroup = {
  type: "group",
  combinator: "or",
  rules: [],
};
