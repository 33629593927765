import styled, { css } from "styled-components";

export const QueryBuilderContainer = styled.form``;

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

export const Button = styled.button`
  background-color: transparent;
  color: #20769a;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  gap: 5px;
  padding: 0;
  align-items: center;
  justify-content: center;
`;

export const QueryBuilderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
`;

export const PreviewButton = styled.button`
  background-color: transparent;

  color: #20769a;
  font-size: 1rem;
  font-weight: 400;

  cursor: pointer;

  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 3px;
  padding: 8px 12px;

  ${(props) =>
    props.active &&
    css`
      background-color: #27a1c9;
      color: #ffffff;
      border: 1px solid #3fafd1;
    `}

  &:hover {
  }
`;
