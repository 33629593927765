import { find } from "lodash";
import React, { useEffect, useState, useMemo } from "react";

import TableOneWrapper from "../dashboard-table-one-wrapper";

import { getTableOneData } from "../../../../services/insights-data-service";

import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";

function AwareTableOne({ setErrorMsg, setValidCharts }) {
  const { subcohortList, dispatch: subcohortListDispatch } =
    useIdentifySubcohortList();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex, filtersConfig, table1Config, settings } = cohortConfigs;
  const { enabledModulesCount } = settings;
  const [responseList, setResponseList] = useState({});

  const [tableOneWrapperConfig, setTableOneWrapperConfig] = useState({});

  async function getTableData(cohortQuery) {
    const response = await getTableOneData(
      cohortIndex.index_name,
      cohortQuery,
      table1Config
    );

    if (response.data?.valid) {
      return {
        cohortSize: response.data.data.cohort_size,
        data: response.data.data.result,
      };
    }

    setValidCharts(false);
    setErrorMsg(response.data.messages.join());
    return {};
  }

  function getTableOneWrapperConfig(
    subtables,
    cohorts,
    exportEnabled,
    fullScreenEnabled
  ) {
    const table = {};
    if (subtables.length > 0) {
      table.exportEnabled = exportEnabled;
      table.fullScreenEnabled = fullScreenEnabled;

      const newCohortsConfig = [];
      Object.keys(cohorts).forEach((id, i) => {
        newCohortsConfig.push({
          name: id,
          color: subcohortList[id].color,
          label: subcohortList[id].name,
          filters: "",
          // DashboardHelper.getSubcohortDefaultName(
          //   subcohortList[id].filterList,
          //   filtersConfig
          // ),
        });
      });
      table.cohorts = newCohortsConfig;
      table.subtables = subtables;
      table.title = "Table 1 - Patient characteristics";
    }
    return table;
  }

  function parseResponse() {
    let subtables = [];
    const cohorts = {};

    Object.keys(responseList).forEach((id) => {
      if (id in subcohortList && subcohortList[id].visible) {
        if (subtables.length === 0) {
          subtables = responseList[id].data.map((subtable) => ({
            ...subtable,
            rows: subtable.rows.map((row) => {
              let newRow = {};
              newRow[id] = row;
              return newRow;
            }),
          }));
        } else {
          subtables = subtables.map((subtable) => {
            let newSubtable = find(
              responseList[id].data,
              (s) => s.subtable_name === subtable.subtable_name
            );

            let newRows = subtable.rows.map((row, i) => {
              let newRow = { ...row };
              newRow[id] = newSubtable.rows[i];
              return newRow;
            });

            return { ...subtable, rows: newRows };
          });
        }
        cohorts[id] = responseList[id].cohortSize;
      }
    });
    return { subtables, cohorts };
  }

  useEffect(async () => {
    const newResponseList = { ...responseList };
    let fetchedID;

    // Trigger the get table data for the subcohort that was changed or added

    for (const id in subcohortList) {
      if (
        !subcohortList[id].fetchedData.tableOne &&
        subcohortList[id].status !== "isCreating"
      ) {
        setTableOneWrapperConfig([]); // to assure that a loading will appear while getting the data
        if (table1Config) {
          const response = await getTableData(subcohortList[id].query, id);
          newResponseList[id] = response;
          fetchedID = id;

          if (response.cohortSize >= 0)
            subcohortListDispatch({
              type: "UPDATE_SUBCOHORT_SIZE",
              payload: {
                size: response.cohortSize,
                id: id,
              },
            });
        }
      } else if (subcohortList[id].fetchedData.tableOne) {
        newResponseList[id] = subcohortList[id].fetchedData.tableOne;
      }
    }

    //Treat the remove subcohorts, if removed from subcohortList will be removed from response list
    for (const id in newResponseList) {
      if (!(id in subcohortList)) delete newResponseList[id];
    }

    setResponseList(newResponseList);

    //Change the subcohort that were edited or added to loaded
    if (fetchedID)
      subcohortListDispatch({
        type: "UPDATE_SUBCOHORT_FETCHED_DATA",
        payload: {
          chart: "tableOne",
          fetchedData: newResponseList[fetchedID],
          id: fetchedID,
        },
      });
  }, [
    subcohortList.cohort?.status,
    subcohortList["subcohort-a"]?.status,
    subcohortList["subcohort-b"]?.status,
    subcohortList["subcohort-c"]?.status,
    table1Config,
  ]);

  // Trigger build of the maps config structure
  useEffect(() => {
    const { subtables, cohorts } = parseResponse();
    const newConfig = getTableOneWrapperConfig(
      subtables,
      cohorts,
      settings.table_1_export_enabled,
      enabledModulesCount !== 1
    );
    setTableOneWrapperConfig(newConfig);
  }, [
    responseList.cohort,
    responseList["subcohort-a"],
    responseList["subcohort-b"],
    responseList["subcohort-c"],
    subcohortList?.cohort?.visible,
  ]);
  return (
    <TableOneWrapper
      config={tableOneWrapperConfig}
      id="tableOne"
      style={enabledModulesCount === 3 ? { gridRow: "2 span" } : {}}
    />
  );
}

export default AwareTableOne;
