import React from "react";
import { MdAdd } from "react-icons/md";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { useModal } from "../../../../../components/modal";
import Tooltip from "../../../../../components/tooltip";
import InfoTooltip from "../../../../../components/tooltip-info-icon";

import { useIdentifySubcohortList } from "../../../../../hooks/useIdentifySubcohortList";
import { useIdentifySimulation } from "../../../../../hooks/useIdentifySimulation";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../services/mixpanel-tracking";

import SimulationModal from "./simulation-modal";

import { Container, AddSimulationButton } from "./styles";

function AddSimulation() {
  const { subcohortList } = useIdentifySubcohortList();
  const { dispatch: simulationDispatch } = useIdentifySimulation();

  const location = useLocation();
  const history = useHistory();

  function handleAddSimulationSubcohort() {
    if (Object.keys(subcohortList).length > 1) toggleSimulationModal();
    else {
      const selectedCohort = {
        name: subcohortList.cohort.name,
        id: "cohort",
        query: subcohortList.cohort.query,
        color: subcohortList.cohort.color,
        subcohortSize: subcohortList.cohort.subcohortSize,
        status: "isLoading",
      };
      simulationDispatch({
        type: "CHANGE_SELECTED_COHORT",
        payload: selectedCohort,
      });

      mixpanelIdentify(localStorage.email);
      mixpanelTrack("Add Simulation", {
        selectedCohort: {
          type: "cohort",
          name: subcohortList.name,
          query: subcohortList.query,
        },
        urlPathname: location.pathname,
      });

      history.push({ pathname: "/clint-identify/simulation" });
    }
  }

  const {
    toggleModal: toggleSimulationModal,
    isShowingModal: isShowingSimulationModal,
  } = useModal();

  return (
    <Container>
      <AddSimulationButton
        disabled={false}
        onClick={() => handleAddSimulationSubcohort()}
      >
        <span>
          <InfoTooltip
            description={
              "A simulation will apply a COX proportional hazard model to a cohort or subcohort."
            }
            position={"top"}
          >
            Add simulation
          </InfoTooltip>
        </span>
        <Tooltip description={"Add a simulation"} position={"top"}>
          <MdAdd />
        </Tooltip>
      </AddSimulationButton>
      <SimulationModal
        toggleModal={toggleSimulationModal}
        isShowingModal={isShowingSimulationModal}
      />
    </Container>
  );
}

export default AddSimulation;
