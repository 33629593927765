import React, { useMemo } from "react";
import { FieldContainer } from "./styles";
import Select from "../../basic-inputs/searchable-select";
import { XSmall } from "../../typography";
import { isUndefined } from "lodash";

const Field = ({ selectedField, fields, onChange, error }) => {
  const options = useMemo(() => {
    let newFields = [
      ...fields.map((field) => ({
        label: field.label,
        value: field.value,
      })),
    ];
    return newFields;
  }, [fields]);
  return (
    <FieldContainer>
      <Select
        value={selectedField}
        onChange={onChange}
        options={options}
        marginBottom={0}
        invalid={!isUndefined(error)}
        placeholder={"Choose attribute"}
      ></Select>
      {error ? (
        <XSmall color="#B4436C" marginTop={1}>
          {error}
        </XSmall>
      ) : (
        ""
      )}
    </FieldContainer>
  );
};

export default Field;
