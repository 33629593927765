import React, { useEffect, useState, useRef } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { BiCollapse } from "react-icons/bi";
import { isEmpty } from "lodash";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../services/mixpanel-tracking";

import Subtable from "./subtable";
import KebabMenu from "../../../../components/kebab-menu";
import DownloadCsvMenuItem from "../../../../components/download-csv-menu-item";
import Input from "../../../../components/basic-inputs/input/search-input";
import Loader from "../../../../components/loader";
import { Small } from "../../../../components/typography";

import {
  TablesWrapper,
  FilterNoResultMsg,
  TableElement,
  TableHeader,
  HeaderContainer,
  TableHeaderSpecialCell,
  ActionsContainer,
} from "./styles";
import InternalPanel from "../../../../components/panel/internal";
import FullPanel from "../../../../components/panel/fullscreenPanel";

import { convertCohortsToCsvString } from "./convert-csv";
import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";

function TableOneWrapper({ config = {}, ...rest }) {
  const headerRef = useRef(null);
  const [subtablesFiltered, setSubtablesFiltered] = useState([]);
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { settings } = cohortConfigs;
  const { enabledModulesCount } = settings;
  const [searchCharacteristicValue, setSearchCharacteristicValue] =
    useState("");

  useEffect(() => {
    if (config.subtables) setSubtablesFiltered(config.subtables);
    else {
      setSubtablesFiltered([]);
    }
  }, [config]);

  const searchCharacteristic = (value) => {
    // TODO: regex throws invalid expression errors with some characters (e.g.: "\"). Fix it
    const regex = new RegExp(value, "i");

    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`${config.title} - TableOne Search`, {
      value,
      urlPathname: location.pathname,
    });

    setSearchCharacteristicValue(value);
    const newSubtables = config.subtables
      .map((table) => {
        const newRows = table.rows.map((row) => {
          const newRow = {};
          Object.keys(row).forEach((cohort) => {
            if (row[cohort].display_name.search(regex) > -1)
              newRow[cohort] = row[cohort];
          });
          if (!isEmpty(newRow)) return newRow;
        });
        return { ...table, rows: newRows.filter((n) => n) };
      })
      .filter((n) => !isEmpty(n.rows));

    setSubtablesFiltered(newSubtables);
  };

  const getEmptyResult = () => {
    return (
      <FilterNoResultMsg>
        Your search didn’t match any items, please try again
      </FilterNoResultMsg>
    );
  };

  const resetFilter = () => {
    setSubtablesFiltered(config.subtables);
    setSearchCharacteristicValue("");
  };

  const getTableElement = () => {
    if (subtablesFiltered.length > 0) {
      return (
        <TableElement>
          <TableHeader ref={headerRef}>
            <tr>
              <th
                style={{ background: "#fff", position: "sticky", left: 0 }}
              ></th>
              {config.cohorts.map((cohort, index) => (
                <TableHeaderSpecialCell
                  key={index}
                  colSpan={2}
                  color={cohort.color}
                >
                  <HeaderContainer title={cohort.label}>
                    <Small color="#666">
                      <b>{cohort.label}</b>
                    </Small>
                  </HeaderContainer>
                </TableHeaderSpecialCell>
              ))}
            </tr>
          </TableHeader>
          {subtablesFiltered.map((subtable, i) => (
            <Subtable
              key={i}
              table={subtable}
              index={i}
              headerStickyHeight={headerRef?.current?.clientHeight}
              cohorts={config.cohorts}
            />
          ))}
        </TableElement>
      );
    }
    return getEmptyResult();
  };

  const getTableTitle = () => {
    if (!isEmpty(config)) return config.title;
    return null;
  };

  const [isOpenFullscreen, setIsOpenFullscreen] = useState(false);

  const getFullPanelActions = () => {
    if (!isEmpty(config)) {
      const { cohorts, subtables, exportEnabled } = config;
      const csvString = convertCohortsToCsvString(cohorts, subtables);

      return (
        <ActionsContainer>
          <Input
            placeholder="Search characteristics"
            onChange={(value) => searchCharacteristic(value)}
            value={searchCharacteristicValue}
            rightIcon={searchCharacteristicValue !== "" ? FaTimesCircle : null}
            rightIconAction={resetFilter}
            marginBottom={0}
          />
          <BiCollapse
            size={24}
            color="4D5F80"
            style={{ cursor: "pointer" }}
            onClick={() => setIsOpenFullscreen(false)}
          />
          {exportEnabled && (
            <KebabMenu width="160px">
              <DownloadCsvMenuItem
                label="Download.csv"
                filename="table-1.csv"
                csvString={csvString}
              />
            </KebabMenu>
          )}
        </ActionsContainer>
      );
    }

    return null;
  };

  const getActions = () => {
    if (!isEmpty(config)) {
      const { cohorts, subtables, exportEnabled, fullScreenEnabled } = config;
      const csvString = convertCohortsToCsvString(cohorts, subtables);

      return (
        <ActionsContainer>
          <Input
            placeholder="Search characteristics"
            onChange={(value) => searchCharacteristic(value)}
            value={searchCharacteristicValue}
            rightIcon={searchCharacteristicValue !== "" ? FaTimesCircle : null}
            rightIconAction={resetFilter}
            marginBottom={0}
          />
          {fullScreenEnabled && (
            <FullPanel
              title={getTableTitle()}
              actions={getFullPanelActions()}
              isOpen={isOpenFullscreen}
              setIsOpen={setIsOpenFullscreen}
              {...rest}
            >
              <TablesWrapper style={{ maxHeight: "80%" }}>
                {getTableElement()}
              </TablesWrapper>
            </FullPanel>
          )}
          {exportEnabled && (
            <KebabMenu width="160px">
              <DownloadCsvMenuItem
                label="Download .csv"
                filename="table-1.csv"
                csvString={csvString}
              />
            </KebabMenu>
          )}
        </ActionsContainer>
      );
    }

    return null;
  };

  return (
    <InternalPanel title={getTableTitle()} actions={getActions()} {...rest}>
      {!isEmpty(config) ? (
        <>
          <TablesWrapper
            style={{ maxHeight: enabledModulesCount <= 3 ? "745px" : "340px" }}
            // This is needed as the Height of the module changes when you have
            // 3 enabled modules. In this case the table need to expand the
            // height of 2 modules rows (745px) and if there is less or more than 3
            // the height is set to only 1 module row ("340px") in the page grid.
            // The height is defined to force a internal scroll, without the max-height
            // definition all the extension of the table is shown
            // without an internal scroll making the geomap expand to the height of the table
            // TODO Investigate a way control this better than setting the height size manually
          >
            {getTableElement()}
          </TablesWrapper>
        </>
      ) : (
        <Loader />
      )}
    </InternalPanel>
  );
}

export default TableOneWrapper;
