import React, { useState, useEffect, useRef } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";

import {
  Container,
  Select,
  OptionsList,
  WrapOptions,
  NoOptionsFound,
} from "./styles";

import Checkbox from "../basic-inputs/checkbox";
import Input from "../basic-inputs/input";
import { includes } from "lodash";

export default function SelectCheckbox({
  value,
  options,
  onChange,
  label,
  disabledOptions = [],
}) {
  const [currentValues, setCurrentValues] = useState([]);
  const [optionsFiltered, setOptionsFiltered] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setCurrentValues(value ? value.split(";;") : []);
  }, [value]);

  useEffect(() => {
    setOptionsFiltered(options);
  }, [options]);

  const resetValues = () => {
    setCurrentValues([]);
    if (onChange) {
      onChange([].toString());
    }
  };

  const select = (itemlabel, checked) => {
    const values = getToggledValues(currentValues, itemlabel, checked);
    setCurrentValues(values);
    if (onChange) {
      onChange(values.join(";;"));
    }
  };

  const getToggledValues = (prevValues = [], newItem, checked) => {
    const newValues = [...prevValues];

    if (checked) {
      newValues.push(newItem);
    } else {
      const pos = newValues.indexOf(newItem);
      newValues.splice(pos, 1);
    }
    return newValues;
  };

  const toggleOptionList = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const handleClickOutside = function (event) {
    const isClickInside =
      wrapperRef.current && wrapperRef.current.contains(event.target);
    if (!isClickInside) {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const filterOptions = (value) => {
    const regex = new RegExp(value, "i");

    const filtered = options.filter(function (row) {
      return row.label.search(regex) > -1;
    });

    setOptionsFiltered(filtered);
  };

  const renderListItems = () => {
    if (optionsFiltered && optionsFiltered.length > 0) {
      const items = [];
      for (let i = 0; i < optionsFiltered.length; i++) {
        const item = optionsFiltered[i];
        items.push(
          <Checkbox
            key={i}
            name={label}
            label={item.label}
            checked={currentValues.includes(item.value)}
            value={item.value}
            disabled={includes(disabledOptions, item.value)}
            onChange={(name, value, checked) => {
              select(value, checked);
            }}
          />
        );
      }
      return items;
    }

    return <NoOptionsFound>No options found</NoOptionsFound>;
  };

  const FilterMinLength = 10;
  const ClearMinLength = 5;
  const optionsLength = options ? options.length : 0;

  return (
    <Container ref={wrapperRef}>
      <Select active={currentValues.length > 0} onClick={toggleOptionList}>
        <span>
          {label}
          {currentValues.length > 0 ? ` (${currentValues.length})` : ""}
        </span>
        <RiArrowDropDownLine size={25} />
      </Select>
      {!isOpen ? (
        ""
      ) : (
        <OptionsList>
          {optionsLength <= FilterMinLength ? (
            ""
          ) : (
            <Input
              onChange={(value) => filterOptions(value)}
              placeholder="Filter"
            />
          )}
          {optionsLength >= ClearMinLength && currentValues.length > 0 ? (
            <a onClick={resetValues}>Clear All</a>
          ) : (
            ""
          )}
          <WrapOptions>{renderListItems()}</WrapOptions>
        </OptionsList>
      )}
    </Container>
  );
}
