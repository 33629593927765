import styled from "styled-components";

export const RuleContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const Field = styled.div`
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
`;
export const Operator = styled.div`
  color: #50565f;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
`;
export const Value = styled.div`
  color: #2f3237;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const NotDefined = styled.div`
  color: #b4436c;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
`;
