import React from "react";
import * as d3 from "d3";
import { event as currentEvent } from "d3";
import _, { cloneDeep, unionBy, uniqBy } from "lodash";

import { Small } from "../../../../typography";

class D3BarGroupPlotLayer extends React.Component {
  constructor(props) {
    super(props);

    this.gWrapperRef = null;
    this.setGWrapperRef = (e) => {
      this.gWrapperRef = e;
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Ensures that the component doesn't get re-rendered without reason

    // Currently, updates every time the width of height changes
    // TODO: add check for data as well
    if (
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.data !== nextProps.data ||
      this.props.normalizedData !== nextProps.normalizedData
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.data && this.props.height !== 0 && this.props.width !== 0) {
      this.renderChartD3Wrapper();
    }
  }

  componentDidUpdate() {
    d3.select(this.gWrapperRef).select("g").remove();
    if (this.props.data) {
      this.renderChartD3Wrapper();
    }
  }

  handleMouseOver(e) {
    /*
    Sets the style of the hovered bar and calls the parent function responsible for showing the
    tooltip. Once we're using the Redux store, this should probably be handled in the layout state.

    D3 mouse events are, by default, bound to the element where the action occurs (i.e, `this`
    refers to the svg element that triggered the event). To keep the React Class context for the
    `this` keyword, we bind the event handler to the `this` of the class, and to still have acess
    to the triggered mouse event, we use the D3 `currentEvent` (imported in the beginning of this
    file).
    */
    if (this.props.config.showTooltip) {
      // d3.select(currentEvent.target).style("fill-opacity", "1");
      const d3Data = e;
      let content = "";
      if (
        this.props.tooltipContent &&
        typeof this.props.tooltipContent === "function"
      ) {
        content = this.props.tooltipContent(d3Data, this.props.chart);
      } else {
        const xLabel = this.props.meta.axesTitle.x
          ? `${this.props.meta.axesTitle.x}: `
          : d3Data.name;
        const xValue = this.props.meta.axesTitle.x ? d3Data.name : "";
        content = (
          <table>
            <tbody>
              <tr key="key">
                <td key={xLabel}>
                  <Small>{xLabel}</Small>
                </td>
                <td key={xValue}>
                  <Small>
                    <b>{xValue}</b>
                  </Small>
                </td>
              </tr>
              {d3Data.values.map((v, i) => (
                <tr key={`value${i}`}>
                  <td key={`value${i}${v.key}`}>
                    <Small>{`${v.key}: `}</Small>
                  </td>
                  <td key={`value${i}${v.value}`}>
                    <Small>
                      <b>
                        {Number.isInteger(v.value)
                          ? v.value
                          : v.value.toFixed(2)}
                      </b>
                    </Small>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      this.props.mouseOverFunc(content);
    }
  }

  handleMouseOut() {
    /*
    Re-sets the style of the bar and calls the parent function responsible for hiding the tooltip.
    Once we're using the Redux store, this should probably be handled in the layout state.
    */
    if (this.props.config.showTooltip) {
      d3.select(currentEvent.target).style("fill-opacity", "0.7");
      this.props.mouseOutFunc();
    }
  }

  renderChartD3Wrapper() {
    const { width } = this.props;
    const { height } = this.props;

    const barPaddingRatio = this.props.config.spacing
      ? this.props.config.spacing
      : 0;
    const { colorPalette } = this.props.config;

    const { hideLegends } = this.props.config;
    const legendPosition = this.props.config.legendPosition
      ? this.props.config.legendPosition
      : "left"; // Top or left for now
    const { chartObservation } = this.props.meta;

    const { hideVerticalAxis } = this.props.config;
    const { hideHorizontalAxis } = this.props.config;
    const axesType = this.props.config.axesXType;
    const alphanumSort =
      typeof this.props.config.alphanumSort === "boolean"
        ? this.props.config.alphanumSort
        : true;
    // const flipAxes = this.props.config.flipAxes;

    const normalizeBy = this.props.config.normalizeBy
      ? this.props.config.normalizeBy
      : "keyTotal";
    const normalizeData = this.props.normalizedData;

    let data = cloneDeep(this.props.data);
    let keys;
    data.map((d) => {
      keys = unionBy(
        keys,
        d.values.map((b) => {
          return b.key;
        })
      );
    });

    const groupData = [];
    keys.map((k) => {
      const values = data.map((d) => {
        const value = d.values.filter(function (v) {
          return v.key == k;
        });
        return {
          key: d.name,
          value: value[0].value,
          group: k,
          std: value[0].std ? value[0].std : 0,
        };
      });
      groupData.push({ name: k, values });
    });

    // index group by key
    if (normalizeData) {
      groupData.map((d) => {
        const demoninator = d.values.filter(function (v) {
          return v.key == normalizeBy;
        })[0].value;
        d.values.map((v) => {
          v.raw_value = v.value;
          v.value = demoninator != 0 ? v.value / demoninator : 0;
          v.std = v.std && demoninator != 0 ? v.std / demoninator : 0;
        });
      });
    }
    data = groupData;

    // The `margin` prop defines the "padding" inside the svg container. For some categorical
    // charts, we want to rotate the labels. This requires more space at the bottom
    const margin = cloneDeep(this.props.marginDefs);
    const axisMargin = cloneDeep(this.props.axisMarginDefs);
    // if (rotateXAxisLabels) margin.bottom += margin.bottomPadding;
    const horizontalMargin = axisMargin.horizontal;
    const verticalMargin = axisMargin.vertical;
    const legendVerticalSpace =
      (!hideLegends && legendPosition == "left" && data.length >= 2) ||
      chartObservation
        ? width * margin.withLegend.vertical
        : 0;
    const legendHorizontalSpace =
      !hideLegends && legendPosition == "top" && data.length >= 2
        ? margin.withLegend.horizontal
        : 0;

    const innerWidth =
      width -
      margin.left -
      margin.right -
      horizontalMargin -
      legendVerticalSpace;
    const innerHeight =
      height -
      margin.top -
      margin.bottom -
      verticalMargin -
      legendHorizontalSpace;

    // Set the Y and X Domain and the Vertical and Horizontal Ranges
    let yDomain;
    let xDomain;
    let x0Domain;
    const { increaseYMaxValue } = this.props;
    const verticalRange = [innerHeight, 0];
    const horizontalRange = [0, innerWidth];

    yDomain = [
      0,
      d3.max(data, function (d) {
        return d3.max(d.values, function (b) {
          return b.value + b.std;
        });
      }) * increaseYMaxValue,
    ];

    if (axesType === "ordinal") {
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      x0Domain = data.map((d) => d.name);
      if (alphanumSort) x0Domain = x0Domain.sort(collator.compare);
    } else {
      console.error("Invalid Axes Type");
    }

    data.map((d) => {
      xDomain = unionBy(
        xDomain,
        d.values.map((b) => {
          return b.key;
        })
      );
    });

    xDomain = uniqBy(xDomain); //

    // D3 selects the parent group tag using the React ref and attach nested groups to it.
    // When the component is updated, children of this node are removed and re-added.
    const parentG = d3.select(this.gWrapperRef);

    const g = parentG
      .append("g")
      .attr(
        "transform",
        `translate(${margin.left + horizontalMargin},${
          margin.top + legendHorizontalSpace
        })`
      );

    // // Define scales.
    let x0Scale;
    let xScale;
    let yScale;

    yScale = d3.scale.linear().domain(yDomain).range(verticalRange);

    x0Scale = d3.scale
      .ordinal()
      .domain(x0Domain)
      .rangeBands(horizontalRange, barPaddingRatio);

    xScale = d3.scale
      .ordinal()
      .domain(xDomain)
      .rangeBands([0, x0Scale.rangeBand()], 0.01);

    const colorScale = d3.scale.ordinal().range(colorPalette || ["#1F61A2"]);

    const barGroup = g
      .selectAll(".bar-group")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "bar-group")
      .attr("transform", function (d) {
        return `translate(${x0Scale(d.name)},0)`;
      })
      .on("mouseover", this.handleMouseOver.bind(this))
      .on("mouseout", this.handleMouseOut.bind(this));

    barGroup
      .selectAll("rect")
      .data(function (b) {
        return b.values;
      })
      .enter()
      .append("rect")
      .attr("width", xScale.rangeBand())
      .attr("x", function (b) {
        return xScale(b.key);
      })
      .style("fill", function (b) {
        return colorScale(b.key);
      })
      .style("fill-opacity", "0.7")
      .attr("y", function (b) {
        return yScale(b.value);
      })
      .attr("height", function (b) {
        return yScale(0) - yScale(b.value);
      })
      .style("stroke", function (b) {
        return colorScale(b.key);
      })
      .style("stroke-width", "1px");

    barGroup
      .selectAll("line.std__line")
      .data(function (b) {
        return b.values;
      })
      .enter()
      .append("line")
      .attr("class", "std__line")
      .attr("x1", function (d) {
        return xScale(d.key) + xScale.rangeBand() / 2;
      })
      .attr("x2", function (d) {
        return xScale(d.key) + xScale.rangeBand() / 2;
      })
      .attr("y1", function (d) {
        return yScale(d.value + d.std);
      })
      .attr("y2", function (d) {
        return yScale(d.value - d.std);
      });

    const showValue = normalizeData ? "raw_value" : "value";
    barGroup
      .selectAll("text.chart__bar-value")
      .data(function (b) {
        return b.values;
      })
      .enter()
      .append("text")
      .attr("class", "chart__bar-value")
      .attr("text-anchor", "middle")
      .attr("x", function (b) {
        return xScale(b.key) + xScale.rangeBand() / 2;
      })
      .attr("y", function (b) {
        return yScale(b.value + b.std) - 5;
      })
      .text(function (b) {
        return Number.isInteger(b[showValue]) || isNaN(b[showValue])
          ? b[showValue]
          : b[showValue].toFixed(2);
      });
  }

  render() {
    return <g ref={this.setGWrapperRef} />;
  }
}

export default D3BarGroupPlotLayer;
