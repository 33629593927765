import React from "react";
import { regressionLinear } from "d3-regression";
import * as d3 from "d3";
import { event as currentEvent } from "d3";
import { cloneDeep, unionBy, uniqBy } from "lodash";

import { Small } from "../../../../typography";

class D3ScatterPlotLayer extends React.Component {
  constructor(props) {
    super(props);

    this.gWrapperRef = null;
    this.setGWrapperRef = (e) => {
      this.gWrapperRef = e;
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Ensures that the component doesn't get re-rendered without reason

    // Currently, updates every time the width of height changes
    // TODO: add check for data as well
    if (
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.data !== nextProps.data
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.data && this.props.height !== 0 && this.props.width !== 0) {
      this.renderChartD3Wrapper();
    }
  }

  componentDidUpdate() {
    d3.select(this.gWrapperRef).select("g").remove();
    if (this.props.data) {
      this.renderChartD3Wrapper();
    }
  }

  handleMouseOver(e) {
    /*
    Sets the style of the hovered bar and calls the parent function responsible for showing the
    tooltip. Once we're using the Redux store, this should probably be handled in the layout state.

    D3 mouse events are, by default, bound to the element where the action occurs (i.e, `this`
    refers to the svg element that triggered the event). To keep the React Class context for the
    `this` keyword, we bind the event handler to the `this` of the class, and to still have acess
    to the triggered mouse event, we use the D3 `currentEvent` (imported in the beginning of this
    file).
    */
    if (this.props.config.showTooltip) {
      d3.select(currentEvent.target).style("fill-opacity", "1");
      const d3Data = e;
      const category = currentEvent.target.getAttribute("data-category");

      const xLabel = this.props.meta.axesTitle.x;
      const yLabel = category || this.props.meta.axesTitle.y;

      const content = (
        <table>
          <tbody>
            <tr key={"key"}>
              <td key={xLabel}>
                <Small>{`${xLabel}: `}</Small>
              </td>
              <td key={d3Data.key}>
                <Small>
                  <b>
                    {d3Data.key}
                    {d3Data.key_range ? " - " + d3Data.key_range : ""}
                  </b>
                </Small>
              </td>
            </tr>
            <tr key={"value"}>
              <td key={yLabel}>
                <Small>{`${yLabel}: `}</Small>
              </td>
              <td key={d3Data.value}>
                <Small>
                  <b>{d3Data.value}</b>
                </Small>
              </td>
            </tr>
          </tbody>
        </table>
      );

      this.props.mouseOverFunc(content);
    }
  }

  handleMouseOut() {
    /*
    Re-sets the style of the bar and calls the parent function responsible for hiding the tooltip.
    Once we're using the Redux store, this should probably be handled in the layout state.
    */
    if (this.props.config.showTooltip) {
      d3.select(currentEvent.target).style("fill-opacity", "0.7");
      this.props.mouseOutFunc();
    }
  }

  renderChartD3Wrapper() {
    const { width } = this.props;
    const { height } = this.props;

    const axesType = this.props.config.axesXType;
    // const rotateXAxisLabels = this.props.config.rotateXAxisLabels;
    const { hideVerticalAxis } = this.props.config;
    const { hideHorizontalAxis } = this.props.config;

    const { hideLegends } = this.props.config;
    const legendPosition = this.props.config.legendPosition
      ? this.props.config.legendPosition
      : "left"; // Top or left for now
    const { chartObservation } = this.props.meta;

    const { colorPalette } = this.props.config;
    const { regressionColorPalette } = this.props.config;
    const { showRegression } = this.props.config;
    const data = cloneDeep(this.props.data);

    let yRange;
    let xDomain;
    const increaseYMaxValue = this.props.increaseYMaxValue;

    yRange = [
      d3.min(data, function (d) {
        return d3.min(d.values, function (b) {
          return b.value;
        });
      }),
      d3.max(data, function (d) {
        return d3.max(d.values, function (b) {
          return b.value;
        });
      }) * increaseYMaxValue,
    ];

    if (axesType === "continuous") {
      xDomain = [
        d3.min(data, function (d) {
          return d3.min(d.values, function (b) {
            return b.key;
          });
        }),
        d3.max(data, function (d) {
          return d3.max(d.values, function (b) {
            return b.key;
          });
        }),
      ];
    } else if (axesType === "ordinal") {
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });

      data.map((d) => {
        xDomain = unionBy(
          xDomain,
          d.values.map((b) => {
            return b.key;
          })
        );
      });
      xDomain = uniqBy(xDomain).sort(collator.compare);
    } else {
      console.error("Invalid Axes Type");
    }

    // The `margin` prop defines the "padding" inside the svg container. For some categorical
    // charts, we want to rotate the labels. This requires more space at the bottom
    const margin = cloneDeep(this.props.marginDefs);
    const axisMargin = cloneDeep(this.props.axisMarginDefs);
    // if (rotateXAxisLabels) margin.bottom += margin.bottomPadding;
    const horizontalMargin = axisMargin.horizontal;
    const verticalMargin = axisMargin.vertical;
    const legendVerticalSpace =
      (!hideLegends && legendPosition == "left" && data.length >= 2) ||
      chartObservation
        ? width * margin.withLegend.vertical
        : 0;
    const legendHorizontalSpace =
      !hideLegends && legendPosition == "top" && data.length >= 2
        ? margin.withLegend.horizontal
        : 0;

    const innerWidth =
      width -
      margin.left -
      margin.right -
      horizontalMargin -
      legendVerticalSpace;
    const innerHeight =
      height -
      margin.top -
      margin.bottom -
      verticalMargin -
      legendHorizontalSpace;

    // D3 selects the parent group tag using the React ref and attach nested groups to it.
    // When the component is updated, children of this node are removed and re-added.
    const parentG = d3.select(this.gWrapperRef);

    const g = parentG
      .append("g")
      .attr(
        "transform",
        "translate(" +
          (margin.left + horizontalMargin) +
          "," +
          (margin.top + legendHorizontalSpace) +
          ")"
      );

    // Define scales. The xScale's domain is the list of "categories", and the yScale's domain
    // goes from 0 to the max value.

    const yScale = d3.scale
      .linear()
      .domain([0, yRange[1]])
      .range([innerHeight, 0]);

    const xScale = d3.scale.linear().domain(xDomain).range([0, innerWidth]);

    const regressionLine = regressionLinear()
      .x((d) => d.key)
      .y((d) => d.value)
      .domain(xDomain);

    // Plot Dots
    data.map((dots, index) => {
      g.selectAll(".dot")
        .data(dots.values)
        .enter()
        .append("circle")
        .attr("class", "dot")
        .attr("r", 3.5)
        .attr("cx", (d) => xScale(d.key))
        .attr("cy", (d) => yScale(d.value))
        .attr("data-category", dots.name)
        .on("mouseover", this.handleMouseOver.bind(this))
        .on("mouseout", this.handleMouseOut.bind(this))
        .style("fill", function (d) {
          if (colorPalette) {
            return colorPalette[index];
          }
          return "#1F61A2";
        });
    });

    if (data.length <= 2 && showRegression) {
      data.map((lines, index) => {
        g.append("line")
          .attr("class", "regression")
          .datum(regressionLine(lines.values))
          .attr("x1", (d) => xScale(d[0][0]))
          .attr("y1", (d) => yScale(d[0][1]))
          .attr("x2", (d) => xScale(d[1][0]))
          .attr("y2", (d) => yScale(d[1][1]))
          .attr("y2", (d) => yScale(d[1][1]))
          .style("stroke-width", "2")
          .style("stroke", function (d) {
            if (regressionColorPalette) {
              return regressionColorPalette[index];
            }
            return "#C25E48";
          });
      });
    }
  }

  render() {
    return <g ref={this.setGWrapperRef}></g>;
  }
}

export default D3ScatterPlotLayer;
