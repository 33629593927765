import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  z-index: 10;
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const SearchInput = styled.input`
  padding: 6.25px 25px 6.25px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  background-color: white;
  transition: border-color 0.3s ease;

  border-color: ${(props) => (props.borderless ? "transparent" : "#DEE4E7")};

  &:focus {
    outline: none;
    border-color: #3fafd1;
    color: #50565f;
  }

  &[readonly] {
    cursor: pointer;
  }

  &[disabled] {
    cursor: not-allowed;
    border-color: #edf0f2;
    background-color: #f5f7f8;
    opacity: 1;
    color: #cccccc;
  }

  ${(props) =>
    props.invalid &&
    css`
      border-color: #dd2542;
    `}

  &:focus {
    outline: none;
    border-color: #3fafd1 !important;
    color: #50565f;
  }
`;

export const OptionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  max-height: 250px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #8f9cb3;
  border-radius: 3px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  z-index: 99999;
  top: 100%;
  left: 0;
`;

export const CategoryLabel = styled.li`
  padding: 10px;
  font-weight: bold;
  background-color: #f0f0f0;
  color: #333;
  cursor: default;
  pointer-events: none;
`;

export const OptionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.highlighted
      ? "#f0f0f0"
      : "white"}; // Highlight the currently selected option

  &:hover {
    background-color: #f0f0f0;
  }

  &[disabled] {
    cursor: not-allowed;
    color: #cccccc;
  }
`;

export const DropdownIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1;
`;
