import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { XSmall, Small } from "../../../../../components/typography";
import { getFieldValuesFromQuery } from "../../../../../components/advanced-query-builder/helpers";

import { useIdentifyCohortConfigs } from "../../../../../hooks/useIdentifyCohortConfig";

import {
  Container,
  AppliedItem,
  Values,
  SpaceBetweenRow,
  CenterRow,
} from "./styles";

function AppliedFilters({ subcohort, disabled }) {
  const appliedFiltersKeys = Object.keys(
    getFieldValuesFromQuery(subcohort.query)
  );
  const appliedFilterCount = appliedFiltersKeys.length;
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { filtersConfig } = cohortConfigs;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (disabled) setIsOpen(false);
  }, [disabled]);

  const getLengthElement = () => {
    if (appliedFilterCount > 0) return `(${appliedFilterCount})`;
    return "";
  };

  const getItemElement = (key) => {
    let itemPharse = "";
    switch (filtersConfig[key].type) {
      case "numerical":
        itemPharse = `between `;
        break;
      default:
        itemPharse = `equals to `;
        break;
    }
    let appliedFilterList = getFieldValuesFromQuery(subcohort.query);
    return (
      <AppliedItem key={key}>
        <Small color="#495F83">{filtersConfig[key].label}</Small>
        <Small color="#666666">
          {itemPharse}{" "}
          <Values
            content={filtersConfig[key].type === "numerical" ? "and" : "or"}
          >
            {appliedFilterList[key].map((v) => (
              <b>{v}</b>
            ))}
          </Values>
        </Small>
      </AppliedItem>
    );
  };

  const getListElement = () => {
    if (appliedFilterCount > 0)
      return appliedFiltersKeys.sort().map((key) => getItemElement(key));
    return "";
  };

  function handleToggleAppliedFilters() {
    if (!disabled) setIsOpen(!isOpen);
  }

  return (
    <Container>
      <SpaceBetweenRow disabled={disabled}>
        <XSmall
          color="--tertiary"
          textTransform="capitalize"
          onClick={() => handleToggleAppliedFilters()}
        >
          <CenterRow>
            Applied Filters {getLengthElement()}
            {isOpen ? (
              <MdKeyboardArrowUp size={18} />
            ) : (
              <MdKeyboardArrowDown size={18} />
            )}
          </CenterRow>
        </XSmall>
        {subcohort.subcohortSize ? (
          <Small color={"#666666"}>
            N={subcohort.subcohortSize?.toLocaleString("en-US")}
          </Small>
        ) : (
          ""
        )}
      </SpaceBetweenRow>
      {isOpen ? getListElement() : ""}
    </Container>
  );
}

export default AppliedFilters;
