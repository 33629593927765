import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  z-index: 10;

  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;

  > svg {
    position: absolute;
    right: 5;
    top: 5;
    z-index: -1;
  }

  &[disabled] {
    cursor: not-allowed;
    border-color: #cccccc;
    opacity: 1;
    color: #cccccc;
  }
`;

export const Select = styled.select`
  padding: 5px 25px 5px 10px;
  height: 30px;
  width: 100%;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  color: #26344d;
  background-color: transparent;

  border: 1px solid;
  border-radius: 3px;

  border-color: ${(props) => (props.borderless ? "transparent" : "#DEE4E7")};

  transition: border-color ease-in-out 0.15s;

  cursor: pointer;

  &[readonly] {
    cursor: not-allowed;
    border-color: #c1c8d4;
    background: #f2f2f2;
    opacity: 1;
    color: #666666;
    background: #f2f2f2;
  }

  &[disabled] {
    cursor: not-allowed;
    border-color: #edf0f2;
    background-color: #f5f7f8;
    opacity: 1;
    color: #cccccc;
  }

  ${(props) =>
    props.invalid &&
    css`
      border-color: #dd2542;
    `}

  &:focus {
    outline: none;
    border-color: #3fafd1 !important;
    color: #50565f;
  }
`;
