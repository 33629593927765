import styled from "styled-components";

export const CellElement = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const CollapsiblesContainer = styled.div`
  padding-top: 16px;
`;

export const Panel = styled.div`
  border: 1px solid #c1c8d4;
  background: #ffffff;
  display: flex;
  flex-direction: row;

  position: absolute;
  top: 1.142rem;
  bottom: 1.142rem;
  left: 1.142rem;
  right: 1.142rem;
  width: calc(100% - 2 * 1.142rem);
  height: calc(100% - 2 * 1.142rem);
  overflow: auto;
`;

export const Header = styled.div`
  margin-bottom: 10px;
  z-index: 999;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PanelTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const SitesTable = styled.div`
  flex: 3 1 900px;
  min-width: 900px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const DrillDownSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const DetailsContainer = styled.div`
  margin-bottom: 15px;
  h4 {
    display: inline;
    color: #4d5f80;
  }

  > div {
    line-height: 163%;
  }
`;

export const BackLink = styled.small`
  color: #495f83;
  cursor: pointer;
  text-decoration: underline;
`;
